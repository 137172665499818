import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const TitleContainer = styled.h1`
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const TextContainer = styled.p`
  text-align: center;
  margin-bottom: 0px;
`;

export const StyledWarningOutlined = styled(WarningOutlined)`
  svg {
    color: ${(p) => p.theme.primary_yellow};
    height: 130px;
    width: 130px;
  }
`;
