import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Spinner } from 'components/UI';
import envconfig from 'config/envconfig';

const LoginRedirect: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (envconfig.authenticationUrl) {
      window.location.href = envconfig.authenticationUrl + '/login';
    } else {
      //TODO - create error page to which the redirect should be made
      navigate('/');
    }
  });

  return <Spinner size="large" />;
};

export default LoginRedirect;
