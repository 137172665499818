import { ValueSetterParams } from 'ag-grid-community';

export const forbidEmptyCellValueSetter = (params: ValueSetterParams) => {
  const valid = params.newValue !== '';

  if (valid) {
    params.data[params.colDef.field || params.column.getColId()] = params.newValue.trim();

    return true;
  }

  return false;
};
