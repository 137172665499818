import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Spinner } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';

const Home: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AllRoutes.Clients);
  });

  return <Spinner size="large" />;
};

export default Home;
