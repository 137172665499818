import React, { useState, useContext, useEffect, useMemo } from 'react';

import { TenantsContext } from 'context';
import { useParams } from 'react-router-dom';

import { CsvMapInput, useGetRequiredFieldsQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

type MappingContextType = {
  assignSizeSystemFromFamily: boolean;
  createdUniverse?: { code: string; name: string };
  currentMappings: CsvMapInput[];
  mappedTypes: string[];
  requiredFields: { [key: string]: string[] };
  setAssignSizeSystemFromFamily: (value: boolean) => void;
  setCreatedUniverse: (value: { code: string; name: string }) => void;
  setMappedTypes: (value: string[]) => void;
  setUniverseAvailable: (value: boolean) => void;
  universeAvailable: boolean;
};

export const MappingContext = React.createContext<MappingContextType>({} as MappingContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const MappingContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const { getTenantDetails } = useContext(TenantsContext);
  const { code } = useParams();
  const tenantDetails = code ? getTenantDetails(code) : undefined;
  const { addError } = useError();

  const [createdUniverse, setCreatedUniverse] = useState<{ code: string; name: string }>();
  const [assignSizeSystemFromFamily, setAssignSizeSystemFromFamily] = useState(false);
  const [universeAvailable, setUniverseAvailable] = useState(true);
  const [mappedTypes, setMappedTypes] = useState<string[]>([]);
  const [currentMappings, setCurrentMappings] = useState<CsvMapInput[]>([]);

  const { data: requiredFieldsData } = useGetRequiredFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const requiredFields = useMemo(
    () => (requiredFieldsData?.requiredFields ? JSON.parse(requiredFieldsData?.requiredFields) : {}),
    [requiredFieldsData?.requiredFields],
  );

  useEffect(() => {
    let mappedFiles = [];
    let currentMappedFiles = [];

    if (tenantDetails?.importFileMappings) {
      mappedFiles = JSON.parse(tenantDetails?.importFileMappings).map((fileMapping: any) => {
        return (
          (!fileMapping.isRequired ||
            (fileMapping.isRequired &&
              fileMapping.fields?.every((field: any) => {
                if (requiredFields[fileMapping.model]?.includes(field.name)) {
                  return field.columns.length > 0;
                }

                return true;
              }))) &&
          fileMapping.model
        );
      });
      currentMappedFiles = JSON.parse(tenantDetails?.importFileMappings);
    }
    setMappedTypes(mappedFiles);
    setCurrentMappings(currentMappedFiles);
  }, [tenantDetails?.importFileMappings, setMappedTypes, requiredFields]);

  return (
    <MappingContext.Provider
      value={{
        assignSizeSystemFromFamily,
        createdUniverse,
        mappedTypes,
        setAssignSizeSystemFromFamily,
        setCreatedUniverse,
        setMappedTypes,
        setUniverseAvailable,
        universeAvailable,
        currentMappings,
        requiredFields,
      }}
    >
      {children}
    </MappingContext.Provider>
  );
};
