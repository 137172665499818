import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.primary_yellow};
  border-color: ${(p) => p.theme.primary_yellow};
  color: ${(p) => p.theme.black};
  :hover,
  :focus {
    background-color: ${(p) => p.theme.pale_yellow};
    border-color: ${(p) => p.theme.pale_yellow};
    color: ${(p) => p.theme.black};
  }
`;
