import { Spin } from 'antd';
import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSpin = styled(Spin)`
  padding: 30px;
  .ant-spin-dot-item {
    background-color: ${(p) => p.theme.primary_yellow};
  }
`;
