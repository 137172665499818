import { defaultNumberValueGetter } from 'helpers/gridCols';

export const CENTERED_HEADER_CLASS = 'centered-columns';
export const CENTERED_CELL_STYLE = { textAlign: 'center' };

export const COLUMN_TYPES = {
  NUMBER_COLUMN: {
    filter: 'agNumberColumnFilter',
    valueGetter: defaultNumberValueGetter,
    cellStyle: { textAlign: 'right' },
  },
  TEXT_COLUMN: {
    filter: 'agTextColumnFilter',
    cellStyle: { textAlign: 'left' },
  },
  BOOLEAN_COLUMN: {
    cellStyle: { textAlign: 'center' },
  },
};
