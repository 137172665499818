import { Button, Collapse } from 'antd';
import styled from 'styled-components';

import { TenantStatus } from 'services/graphql/main';

export const getStatusColor = (status: TenantStatus) => {
  switch (status) {
    case TenantStatus.Active:
      return '#4FAB16';
    case TenantStatus.Pending:
      return '#FFCF00';
    case TenantStatus.Inactive:
      return '#D7300C';
  }
};

export const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & h4 {
    font-size: 22px;
    font-weight: 600;
    margin: 0px 10px;
  }
`;

export const StoreCountWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.soft_gray};
  padding: 7px 20px;
  & p {
    margin-bottom: 0px;
  }
`;

export const StatusButton = styled(Button)<{ action: 'activate' | 'deactivate' }>`
  margin-right: 19px;
  background-color: ${(p) => (p.action === 'deactivate' ? p.theme.red : p.theme.green)};
  border-color: ${(p) => (p.action === 'deactivate' ? p.theme.red : p.theme.green)};
  color: ${(p) => p.theme.white};
  :hover,
  :focus {
    background-color: ${(p) => (p.action === 'deactivate' ? p.theme.red : p.theme.green)};
    border-color: ${(p) => (p.action === 'deactivate' ? p.theme.red : p.theme.green)};
    color: ${(p) => p.theme.white};
  }
`;

export const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 25px 0;
    border-bottom: 1px solid ${(p) => p.theme.soft_gray};

    .ant-collapse-header-text {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-color: transparent !important;
    }
  }

  .ant-collapse-content {
    border-bottom: 1px solid ${(p) => p.theme.soft_gray};
  }

  .ant-collapse-content-box {
    padding: 0 0 25px !important;
  }

  .ant-collapse-arrow {
    font-size: 15px !important;
    vertical-align: -3px !important;
  }
`;
