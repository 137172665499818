import React, { useContext, useEffect, useState } from 'react';

import { TenantsContext } from 'context';
import { useTranslation } from 'react-i18next';

import { CreateClientModal } from 'components/Modals';
import { PrimaryButton } from 'components/UI';
import { Tenant, TenantStatus } from 'services/graphql/main';
import { removeCacheVal } from 'services/utils';

import ClientsTable from './components/ClientsTable';
import { PageTitleWrapper } from './styles';

const Clients: React.FC = () => {
  const { t } = useTranslation('translation');
  const { tenants } = useContext(TenantsContext);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [activeTenants, setActiveTenants] = useState<(Tenant | null)[] | null>();
  const [pendingTenants, setPendingTenants] = useState<(Tenant | null)[] | null>();
  const [inactiveTenants, setInactiveTenants] = useState<(Tenant | null)[] | null>();

  useEffect(() => {
    setActiveTenants(tenants?.filter((tenant) => tenant?.status === TenantStatus.Active));
    setInactiveTenants(tenants?.filter((tenant) => tenant?.status === TenantStatus.Inactive));
    setPendingTenants(tenants?.filter((tenant) => tenant?.status === TenantStatus.Pending));
  }, [tenants]);

  useEffect(() => {
    removeCacheVal('tenantCode');
  }, []);

  return (
    <>
      <PageTitleWrapper>
        <h3>{t('clients.page_title')}</h3>
        <PrimaryButton textKey="clients.add_client" onClick={() => setIsCreateModalOpen(true)} />
      </PageTitleWrapper>
      <ClientsTable title={t('clients.optistock_clients')} status={TenantStatus.Active} tenants={activeTenants} />
      <ClientsTable title={t('clients.clients_in_setup')} status={TenantStatus.Pending} tenants={pendingTenants} />
      <ClientsTable title={t('clients.inactive_clients')} status={TenantStatus.Inactive} tenants={inactiveTenants} />
      <CreateClientModal isOpen={isCreateModalOpen} onCancel={() => setIsCreateModalOpen(false)} />
    </>
  );
};

export default Clients;
