import { Switch } from 'antd';
import styled from 'styled-components';

import { StyledSwitchProps } from './types';

export const StyledSwitch = styled(Switch)<StyledSwitchProps>`
  background-color: ${(p) => (p.$alwaysActive ? p.theme.green : undefined)};

  &.ant-switch-checked {
    background-color: ${(p) => p.theme.green};
  }
`;
