export const getCacheVal = (key: string) => {
  const strValue = window.localStorage.getItem(key);
  let value = null;

  try {
    value = JSON.parse(strValue!);
  } catch (e) {
    value = strValue;
  }

  return value;
};

export const setCacheVal = (key: string, val: string) => window.localStorage.setItem(key, JSON.stringify(val));

export const removeCacheVal = (key: string) => window.localStorage.removeItem(key);
