import React from 'react';

import Spinner from '../Spinner/Spinner';
import { SpinnerContainer } from './styles';

const FullSizeSpinner: React.FC = () => {
  return (
    <SpinnerContainer>
      <Spinner size="large" />
    </SpinnerContainer>
  );
};

export default FullSizeSpinner;
