import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Spinner } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';
import { setCacheVal, useQuery } from 'services/utils';

const RedirectCallback: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    const accessToken = query.get('accessToken');
    const refreshToken = query.get('refreshToken');
    if (accessToken && refreshToken) {
      handleSuccessLogin(accessToken, refreshToken);
    } else {
      //TODO - create error page to which the redirect should be made
      navigate('/');
    }
  });

  const handleSuccessLogin = (token: string, refreshToken: string) => {
    setCacheVal('token', token);
    setCacheVal('refreshToken', refreshToken);
    navigate(AllRoutes.Clients);
  };

  return <Spinner size="large" />;
};

export default RedirectCallback;
