import React from 'react';

import { Navigate } from 'react-router-dom';

import AllRoutes from 'screens/AllRoutes';
import { getCacheVal, isValidToken } from 'services/utils';

type Props = {
  children: any;
};

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const isLoggedIn = isValidToken(getCacheVal('token'));

  if (!isLoggedIn) {
    return <Navigate to={AllRoutes.Unauthorized} />;
  }

  return children;
};

export default ProtectedRoute;
