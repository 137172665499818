import React from 'react';

import { TenantsContextProvider } from 'context';
import { Outlet } from 'react-router-dom';

import Sider from 'layouts/Sider/Sider';

import { PageWrapper, StyledLayout, ContentWrapper } from './styles';

type Props = {
  children?: React.ReactNode;
};

const MainLayout = (props: Props) => {
  return (
    <TenantsContextProvider>
      <StyledLayout hasSider>
        <Sider />

        <PageWrapper>
          <ContentWrapper>{props.children ? props.children : <Outlet />}</ContentWrapper>
        </PageWrapper>
      </StyledLayout>
    </TenantsContextProvider>
  );
};

export default MainLayout;
