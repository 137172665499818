import { useContext } from 'react';

import { ErrorContext } from '../../context';

const useError = () => {
  const { errors, addError, clearErrors, addCustomError } = useContext(ErrorContext);

  return { errors, addError, clearErrors, addCustomError };
};

export default useError;
