import React, { useState, useContext } from 'react';

import { Modal } from 'antd';
import { Form, Input } from 'antd';
import { TenantsContext } from 'context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton, NakedButton } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';
import { useCreateTenantMutation } from 'services/graphql/main';
import { useError } from 'services/utils';

import { Label } from './styles';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

type CreateInput = {
  code: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  contactWebsite?: string;
  name: string;
};

const CreateClientModal: React.FC<Props> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { addError } = useError();
  const { refetch } = useContext(TenantsContext);

  const [createInput, setCreateInput] = useState<CreateInput>({
    name: '',
    code: '',
  });

  const [createTenant] = useCreateTenantMutation({ onError: (err) => addError(err, 'warning') });

  const handleChange = (e: { target: HTMLInputElement }) => {
    setCreateInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const submitForm = async () => {
    if (!createInput.name) return;

    const res = await createTenant({
      variables: {
        tenantInput: createInput,
      },
    });
    if (res.data?.createTenant.id) {
      refetch();
      res.data?.createTenant.code && navigate(AllRoutes.ClientDetails.replace(':code', res.data?.createTenant.code));
    }
  };

  return (
    <Modal
      centered
      open={isOpen}
      onOk={submitForm}
      onCancel={onCancel}
      title={t('createClient.create_client')}
      footer={[
        <NakedButton key="cancel" onClick={onCancel} style={{ border: 'none', marginRight: 15 }}>
          {t('common.cancel')}
        </NakedButton>,
        <PrimaryButton
          onClick={submitForm}
          disabled={!createInput.name || !createInput.code}
          key="confirm"
          text={t('common.confirm')}
        />,
      ]}
    >
      <Form autoComplete="off">
        <Form.Item>
          <Label htmlFor="name">{t('createClient.name')}</Label>
          <Input
            value={createInput.name}
            onChange={handleChange}
            placeholder={t('createClient.name_placeholder')}
            id="name"
            name="name"
          />
        </Form.Item>
        <Form.Item>
          <Label htmlFor="code">{t('createClient.code')}</Label>
          <Input
            value={createInput.code}
            onChange={(e) => {
              const reg = /^[A-Za-z0-9_.-]{1,10}$/;
              if (reg.test(e.target.value) || e.target.value === '' || e.target.value === ' ') {
                handleChange(e);
              }
            }}
            placeholder={t('createClient.code')}
            id="code"
            name="code"
          />
        </Form.Item>
        <Form.Item>
          <Label htmlFor="contact_name">{t('createClient.contact_name')}</Label>
          <Input
            value={createInput.contactName}
            onChange={handleChange}
            placeholder={t('createClient.contact_name_placeholder')}
            id="contact_name"
            name="contactName"
          />
        </Form.Item>
        <Form.Item>
          <Label htmlFor="contact_email">{t('createClient.contact_email')}</Label>
          <Input
            value={createInput.contactEmail}
            onChange={handleChange}
            placeholder={t('createClient.contact_email_placeholder')}
            id="contact_email"
            name="contactEmail"
          />
        </Form.Item>
        <Form.Item>
          <Label htmlFor="contact_phone">{t('createClient.contact_phone')}</Label>
          <Input
            value={createInput.contactPhone}
            onChange={(e) => {
              const reg = /^-?\+?\d*(\.\d*)?$/;
              if (reg.test(e.target.value) || e.target.value === '' || e.target.value === ' ') {
                handleChange(e);
              }
            }}
            placeholder={t('createClient.contact_phone_placeholder')}
            id="contact_phone"
            name="contactPhone"
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Label htmlFor="contact_website">{t('createClient.contact_website')}</Label>
          <Input
            value={createInput.contactWebsite}
            onChange={handleChange}
            placeholder={t('createClient.contact_website_placeholder')}
            id="contact_website"
            name="contactWebsite"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClientModal;
