import { KeyboardEvent } from 'react';

import { KEYS } from 'constants/keyboard';

const { KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB } = KEYS;

export const isLeftOrRight = (event: KeyboardEvent) => {
  return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
};

export const isCharNumeric = (charStr: string) => {
  return !!/\d/.test(charStr);
};

export const isKeyPressedNumeric = (event: KeyboardEvent) => {
  const charStr = event.key;

  return isCharNumeric(charStr);
};
export const isKeyPressedNumericOrDecimal = (event: KeyboardEvent) => {
  const charStr = event.key;

  return isCharNumeric(charStr) || charStr === '.';
};

export const deleteOrBackspace = (event: KeyboardEvent) => {
  return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
};

export const finishedEditingPressed = (event: KeyboardEvent) => {
  const key = event.key;

  return key === KEY_ENTER || key === KEY_TAB;
};

export const getSeparators = (locale: string) => {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  const numberFormattedToParts = Intl.NumberFormat('en-IN').formatToParts(numberWithGroupAndDecimalSeparator);

  return {
    decimal: numberFormattedToParts.find((part) => part.type === 'decimal')?.value,
    group: numberFormattedToParts.find((part) => part.type === 'group')?.value,
  };
};

export const isAllowedNumericFieldKey = (locale: string, event: KeyboardEvent) => {
  const { key } = event;
  const allowedKeys = [KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB];

  return isCharNumeric(key) || allowedKeys.includes(key) || isLeftOrRight(event) || deleteOrBackspace(event);
};

export const isAllowedDecimalFieldKey = (locale: string, event: KeyboardEvent) => {
  const { key } = event;
  const allowedKeys = [KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB];
  const separators = getSeparators(locale);

  //@ts-ignore
  if ('value' in event.target && key === separators.decimal && event.target.value.includes(key)) {
    return false;
  }

  return (
    isCharNumeric(key) ||
    allowedKeys.includes(key) ||
    separators.decimal === key ||
    isLeftOrRight(event) ||
    deleteOrBackspace(event)
  );
};

type ValidateGridCustom = {
  code?: string;
  isValid: boolean;
};

type GridValidationsTypes = {
  [key: string]: string;
};

const GRID_VALIDATIONS_TYPES: GridValidationsTypes = {
  duplicates: 'doesDuplicatedValuesExist',
  emptyCell: 'doesEmptyCellExist',
  length: 'isCellsValuesLengthValid',
  total: 'isTotalValid',
};

const GRID_VALIDATIONS: any = {
  doesDuplicatedValuesExist: (data: any) => {
    if (data[0]) {
      const keys = Object.keys(data[0]);

      for (const key of keys) {
        const rawData = data?.map((x: any) => x?.[key]?.toLowerCase());
        const noDuplicateData = new Set(rawData);

        if (rawData?.length !== noDuplicateData?.size) {
          const result = {
            isValid: false,
            code: 'DUPLICATES_EXIST_IN_GRID',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  doesEmptyCellExist: (data: any) => {
    for (const row of data) {
      for (const key in row) {
        if (row[key].trim() === '') {
          const result = {
            isValid: false,
            code: 'EMPTY_CELLS_EXIST_IN_GRID',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  isCellsValuesLengthValid: (data: any) => {
    for (const row of data) {
      for (const key in row) {
        if (row[key]?.length < 1 || row[key]?.length > 50) {
          const result = {
            isValid: false,
            code: 'FIELD_MAX_MIN_LENGTH',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  isTotalValid: (data: any) => {
    const areTotalsValid = data.every((value: { total: number }) => value?.total === 100);

    if (!areTotalsValid) {
      const result = {
        isValid: false,
        code: 'TOTAL_MUST_BE_100',
      };

      return result;
    }

    return { isValid: true };
  },
};

export const validateGridCustom = (types: string[], data: any): ValidateGridCustom => {
  for (const type of types) {
    const currentValidation = GRID_VALIDATIONS[GRID_VALIDATIONS_TYPES[type]](data);

    if (!currentValidation.isValid) {
      return currentValidation;
    }
  }

  return { isValid: true };
};
