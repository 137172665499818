import { Menu } from 'antd';
import styled from 'styled-components';

import { Sider } from 'components/UI';

export const StyledMenu = styled(Menu)`
  min-height: 100%;
  background-color: ${(p) => p.theme.dark_gray};
  color: ${(p) => p.theme.white};
  .ant-menu-item:hover,
  .ant-menu-item a:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title-active,
  .ant-menu-item-open,
  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title-selected,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: ${(p) => p.theme.white};
  }
  .ant-menu-item-selected a,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-weight: bold;
  }
  .ant-menu-item-selected {
    background-color: ${(p) => p.theme.light_gray} !important;
  }
  .ant-menu-inline .ant-menu-item:after {
    border-right: 3px solid ${(p) => p.theme.primary_yellow};
  }
  .ant-menu-item a {
    color: ${(p) => p.theme.white};
  }
  .ant-menu-item-selected:after {
    border-right: 3px solid ${(p) => p.theme.primary_yellow};
  }
`;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    top: 0px;
    display: flex;
    flex-direction: column;
  }
`;

export const LogoWrapper = styled.div``;
