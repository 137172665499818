import React from 'react';

import App from 'App';
import { MappingContextProvider } from 'context';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout/MainLayout';

import AllRoutes from './AllRoutes';
import ClientDetails from './ClientDetails/ClientDetails';
import Clients from './Clients/Clients';
import Home from './Dashboard/Home';
import LoginRedirect from './Login/LoginRedirect';
import ProtectedRoute from './Login/ProtectedRoute';
import RedirectCallback from './Login/RedirectCallback';
import News from './News/News';
import Unauthorized from './Unauthorized/Unauthorized';

const Routing: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={AllRoutes.Login} element={<LoginRedirect />} />
        <Route path={AllRoutes.AuthorizationCallback} element={<RedirectCallback />} />
        <Route path={AllRoutes.Unauthorized} element={<Unauthorized />} />
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path={AllRoutes.Dashboard} element={<Home />} />
          <Route path={AllRoutes.Clients} element={<Clients />} />
          <Route path={AllRoutes.News} element={<News />} />
          <Route
            path={AllRoutes.ClientDetails}
            element={
              <MappingContextProvider>
                <ClientDetails />
              </MappingContextProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Routing;
