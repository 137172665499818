import React, { useEffect, useState } from 'react';

import { Tenant, useGetTenantsQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

type TenantsContextType = {
  getTenantDetails: (code: string) => Tenant | undefined | null;
  refetch: () => void;
  tenants: (Tenant | null)[] | null | undefined;
};

export const TenantsContext = React.createContext<TenantsContextType>({} as TenantsContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const TenantsContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [tenants, setTenants] = useState<(Tenant | null)[] | null>();

  const { addError } = useError();
  const { data, refetch } = useGetTenantsQuery({ onError: (err: any) => addError(err, 'error') });

  const getTenantDetails = (code: string) => {
    if (tenants?.length) {
      return tenants.find((tenant) => tenant?.code === code);
    }
  };

  useEffect(() => {
    if (data) {
      // TODO remove any
      setTenants(data.tenants as any);
    }
  }, [data]);

  return (
    <TenantsContext.Provider
      value={{
        tenants,
        refetch,
        getTenantDetails,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
};
