import React from 'react';

import UnauthorizedImg from 'assets/images/unаuthorized.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';

import { PageWrapper, UnauthorizedText } from './styles';

const Unauthorized: React.FC = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <img src={UnauthorizedImg} alt="unauthorized" />
      <UnauthorizedText>{t('common.unauthorized_text')}</UnauthorizedText>
      <PrimaryButton textKey="common.login" onClick={() => navigate(AllRoutes.Login)} />
    </PageWrapper>
  );
};

export default Unauthorized;
