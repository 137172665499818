import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { langs } from './config/i18n';
import useQuery from './services/utils/useQuery';

const languageIsValid = (language: string) =>
  Object.keys(langs).filter((lng) => language.split('-')[0] === lng).length > 0;

type WrapperProps = {
  children: React.ReactNode;
};

const I18nWrapper: React.FC<WrapperProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const query = useQuery();
  const lang = query.get('lang');

  useEffect(() => {
    if (lang && languageIsValid(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return <div>{children}</div>;
};

export default I18nWrapper;
