import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    min-height: 30px;
  }
  .Toastify__close-button {
    align-self: center;
  }
`;
