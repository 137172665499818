import React from 'react';

import { ApolloProvider } from '@apollo/client';
import I18nWrapper from 'I18nWrapper';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import { ErrorNotification } from 'components/UI';
import { apolloClient } from 'config/apolloClient';

import { ErrorContextProvider } from './context';

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <I18nWrapper>
          <ErrorContextProvider>
            <Outlet />
            <ErrorNotification />
          </ErrorContextProvider>
        </I18nWrapper>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
