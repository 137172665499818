import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from 'ag-grid-community';

import { KEYS } from 'constants/keyboard';
import { deleteOrBackspace, finishedEditingPressed, isLeftOrRight } from 'helpers/validations';

const { KEY_BACKSPACE, KEY_DELETE } = KEYS;

const allowedCharsRegex = /[^0-9a-zA-Z.\-_/$&+,:;=?@#|'<>^*()%! ]/;

const AlphanumericCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const createInitialState = () => {
    let startValue;

    if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
    }

    return {
      value: startValue,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState(initialState.value);
  const refInput = useRef<HTMLInputElement>(null);

  // focus on the input
  useEffect(() => {
    // get ref from React component
    window.setTimeout(() => {
      const eInput = refInput.current!;
      eInput?.focus();
      eInput?.select();
    });
  }, []);

  /* Utility Methods */
  const cancelBeforeStart = props.charPress && !allowedCharsRegex.test(props.charPress);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLeftOrRight(event) || deleteOrBackspace(event)) {
      event.stopPropagation();

      return;
      1;
    }

    if (!finishedEditingPressed(event) && !!allowedCharsRegex.test(event.key)) {
      if (event.preventDefault) event.preventDefault();
    }
  };

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return cancelBeforeStart;
      },
    };
  });

  return (
    <input
      type="text"
      ref={refInput}
      className="simple-text-editor"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onKeyDown={(event) => onKeyDown(event)}
    />
  );
});

AlphanumericCellEditor.displayName = 'AlphanumericCellEditor';

export default AlphanumericCellEditor;
