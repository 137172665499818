import React, { useCallback, useEffect, useState } from 'react';

import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FullSizeSpinner, InputFormTooltip, PrimaryButton, Spinner } from 'components/UI';
import {
  ErpType,
  TenantDetailsDto,
  useGetOutputFileSettingsQuery,
  useUpdateOutputFileSettingsMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { StyledSwitch } from './styles';
import { FormsValidation } from './types';

type OutputFileSettingsInputs = {
  erpType?: TenantDetailsDto['erpType'];
  excludeZeroValues: TenantDetailsDto['excludeZeroValues'];
  exportFileDelimiter?: TenantDetailsDto['exportFileDelimiter'];
  exportFileHeader?: TenantDetailsDto['exportFileHeader'];
  exportFileLineTemplate?: TenantDetailsDto['exportFileLineTemplate'];
  exportFileName?: TenantDetailsDto['exportFileName'];
  exportFolder?: TenantDetailsDto['exportFolder'];
  exportHour: TenantDetailsDto['exportHour'];
  sendChangedLogsOnly: TenantDetailsDto['sendChangedLogsOnly'];
};

const hoursOptions = new Array(24)
  .fill(null)
  .map((_item, idx) => ({ value: idx, label: `${idx < 10 ? 0 : ''}${idx}` }));

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 4 },
};

const validateMessages = {
  required: '${label} is required!',
};

const OutputFileSettings: React.FC<{
  onFormValidation: React.Dispatch<React.SetStateAction<FormsValidation>>;
  tenantId: number;
}> = ({ tenantId, onFormValidation }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();
  const { addError } = useError();
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const successMessage = () => toast.success(t('common.successfully_saved'), { theme: 'colored' });

  const { data, loading, refetch } = useGetOutputFileSettingsQuery({
    variables: { id: tenantId },
  });

  const [updateTenant, { error: mutationError }] = useUpdateOutputFileSettingsMutation({
    onError: (err) => addError(err, 'error'),
    onCompleted: () => {
      setIsSaving(false);
      setIsSubmitButtonDisabled(true);
      successMessage();
    },
  });

  const isFormValid = useCallback(() => {
    const values = form.getFieldsValue();
    if (values.exportFileHeader || values.exportFileHeader === null || values.exportFileHeader === '')
      delete values['exportFileHeader'];

    return Object.values(values).filter((el: unknown) => el === null || el === undefined || el === '').length === 0;
  }, [form]);
  useEffect(() => {
    if (data) {
      onFormValidation((prev) => ({
        ...prev,
        outputFileSettings: isFormValid(),
      }));
    }
  }, [data, onFormValidation, isFormValid]);

  const handleSubmit = async (inputsValues: OutputFileSettingsInputs) => {
    setIsSaving(true);

    await updateTenant({
      variables: {
        tenantInput: {
          id: tenantId,
          ...inputsValues,
        },
      },
    });

    if (!mutationError) await refetch();
  };

  return !loading && data?.tenant ? (
    <Form
      {...layout}
      validateMessages={validateMessages}
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={data.tenant}
      onValuesChange={() => {
        setIsSubmitButtonDisabled(false);

        onFormValidation((forms) => ({
          ...forms,
          outputFileSettings: isFormValid(),
        }));
      }}
      style={{ position: 'relative' }}
    >
      {isSaving ? <FullSizeSpinner /> : null}

      <Form.Item
        rules={[{ required: true }]}
        label={
          <>
            {t('clientDetails.name_of_the_output')}
            <InputFormTooltip text="clientDetails.name_of_the_output_tooltip" />
          </>
        }
        name="exportFileName"
      >
        <Input placeholder="RP{0}{1}{2}{3}{4}.csv" />
      </Form.Item>

      <Form.Item rules={[{ required: true }]} label={t('clientDetails.erp_type')} name="erpType">
        <Select id="erpType">
          {Object.values(ErpType).map((erpType) => (
            <Select.Option key={erpType}>{erpType}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          onFormValidation((forms) => ({
            ...forms,
            outputFileSettings: isFormValid(),
          }));

          return prevValues.erpType !== currentValues.erpType;
        }}
      >
        {({ getFieldValue }) =>
          getFieldValue('erpType') !== ErpType.As400 && getFieldValue('erpType') !== ErpType.Y2 ? (
            <Form.Item
              name="exportFileHeader"
              label={
                <>
                  {t('clientDetails.file_header')} <InputFormTooltip text="clientDetails.file_header_tooltip" />
                </>
              }
            >
              <Input placeholder="<ENTETE>EXP 000.000" />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item rules={[{ required: true }]} label={t('clientDetails.delimiter')} name="exportFileDelimiter">
        <Input id="exportFileDelimiter" placeholder="," />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          onFormValidation((forms) => ({
            ...forms,
            outputFileSettings: isFormValid(),
          }));

          return prevValues.erpType !== currentValues.erpType;
        }}
      >
        {({ getFieldValue }) =>
          getFieldValue('erpType') !== ErpType.As400 && getFieldValue('erpType') !== ErpType.Colombus ? (
            <Form.Item
              rules={[{ required: true }]}
              label={
                <>
                  {t('clientDetails.file_row_template')}
                  <InputFormTooltip text="clientDetails.file_row_template_tooltip" />
                </>
              }
              name="exportFileLineTemplate"
            >
              <Input id="exportFileLineTemplate" placeholder="{1}{0}{2}{0}{3}" />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        rules={[{ required: true }]}
        label={t('clientDetails.sending_all_optimal_stock')}
        name="sendChangedLogsOnly"
        valuePropName="checked"
      >
        <StyledSwitch id="sendChangedLogsOnly" checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.sendChangedLogsOnly !== currentValues.sendChangedLogsOnly
        }
      >
        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.exclude_quantities')}
          name="excludeZeroValues"
          valuePropName="checked"
        >
          <StyledSwitch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} id="excludeZeroValues" />
        </Form.Item>
      </Form.Item>

      <Form.Item rules={[{ required: true }]} label={t('clientDetails.sending_time')} name="exportHour">
        <Select id="exportHour" options={hoursOptions} style={{ width: '70px' }} />
      </Form.Item>

      <Form.Item rules={[{ required: true }]} label="Output file Folder name" name="exportFolder">
        <Input id="exportFolder" placeholder="OUTPUT" />
      </Form.Item>

      {/* <PrimaryButton textKey="Force generate output file" onClick={() => console.log('Force generate output file')} /> */}

      <PrimaryButton
        type="primary"
        textKey="common.save"
        htmlType="submit"
        disabled={!isFormValid() || isSubmitButtonDisabled}
      />
    </Form>
  ) : (
    <Spinner size="large" />
  );
};

export default OutputFileSettings;
