import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  background: transparent;
  min-height: 100vh;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 20px;
`;
