import React from 'react';

import { SiderProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import styled, { DefaultTheme } from 'styled-components';

export type StyledSiderProps = SiderProps & { theme?: DefaultTheme };

const LightSider: React.FC<StyledSiderProps> = (props) => <Sider {...props} theme="light" />;

export const StyledSider = styled(LightSider)`
  background-color: ${(p) => p.theme.dark_gray};
  min-height: 100vh;

  .ant-layout-sider-children {
    height: unset;
    position: sticky;
    top: 20px;
  }
`;
