import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Select, Switch, Form } from 'antd';
import styled from 'styled-components';

export const MainInformationContent = styled.div`
  padding-top: 25px;
`;

export const SectionTitle = styled.h5`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
`;

export const InfoLineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & label {
    font-size: 14px;
    font-weight: 500;
    width: 20%;
  }
`;

export const DetailsWrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.soft_gray};
  display: flex;
  align-items: center;
  padding: 25px 0px;
  border-bottom: 1px solid ${(p) => p.theme.soft_gray};

  & span {
    font-size: 20px;
    color: ${(p) => p.theme.dark_gray};
  }
  & input {
    margin: 0px 15px;
  }
`;

export const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  &:last-of-type {
    input {
      border-right-color: transparent !important;
    }
  }

  .anticon-edit {
    position: absolute;
    right: 28px;
    color: ${(p) => p.theme.light_gray};
    z-index: 0;
    pointer-events: none;
  }

  input {
    position: relative;
    border: 1px solid transparent !important;
    border-right-color: ${(p) => p.theme.soft_gray} !important;
    z-index: 1;

    &:focus {
      border-color: ${(p) => p.theme.soft_gray} !important;
    }

    &:hover {
      border-color: ${(p) => p.theme.light_blue} !important;
    }
  }
`;

export const SubTitle = styled.h6`
  font-size: 14px;
  font-weight: 700;
`;

export const StyledSection = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 40px;
  }
`;

export const GridWrapper = styled.div`
  div.ag-root .ag-body-viewport {
    max-height: 326px;
    overflow-y: scroll;
  }

  .ag-header-viewport {
    flex: unset;
  }

  .ag-pinned-right-header {
    min-width: unset !important;
    max-width: unset !important;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    padding-left: 0px !important;
  }
`;

export const StyledInput = styled(Input)`
  border: 1px solid transparent;
  box-shadow: none !important;
  transition: background-color 0.25s ease-in-out;

  &,
  &:hover {
    border-color: transparent;
    box-shadow: none;
  }

  &:focus {
    border-color: ${(p) => p.theme.soft_gray};
    background-color: ${(p) => p.theme.white};
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  position: relative;

  label {
    width: 300px;
  }

  input {
    width: 300px;
    padding: 4px 35px 4px 11px;
    transition: background-color 0.25s ease-in-out;
    z-index: 1;
    background-color: transparent;

    &:hover {
      border-color: ${(p) => p.theme.light_blue};
    }
  }

  .anticon-edit {
    position: absolute;
    right: 8px;
    color: ${(p) => p.theme.light_gray};
    font-size: 20px;
    pointer-events: none;
  }

  .ant-picker {
    width: 300px !important;
    padding: 0;
    border-color: transparent !important;

    &:hover + .anticon {
      opacity: 0;
    }

    &.ant-picker-focused {
      box-shadow: none;
    }

    input {
      border: 1px solid transparent;

      &:hover {
        border-color: ${(p) => p.theme.light_blue};
      }

      &:focus {
        background-color: ${(p) => p.theme.white};
        border-color: ${(p) => p.theme.soft_gray};
      }
    }

    .ant-picker-clear {
      top: 16px;
      right: 8px;
      z-index: 1;
      font-size: 20px;
      color: ${(p) => p.theme.dark_gray};
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${(p) => p.theme.green};
  }
`;

export const CsvFileInputSection = styled.div`
  .ant-switch {
    margin-top: 5px;
  }

  .ant-picker {
    padding-top: 0;
    padding-bottom: 0;

    input {
      padding: 4px 20px 4px 11px;
    }
  }
`;

export const OutputFileSettingsContainer = styled(Form)`
  & > div {
    margin-bottom: 16px;
    width: 300px;
  }

  .ant-select {
    width: 130px;
  }
`;

export const MappingUniverseSection = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
`;

export const StyledTablesWrapper = styled.div`
  display: flex;

  .ag-center-cols-clipper {
    min-height: 41px !important;
  }

  .ag-center-cols-container {
    min-height: 41px !important;
  }

  .ag-header-cell[col-id='Code']:after {
    display: none;
  }

  .ag-row-pinned:first-of-type {
    font-weight: 600;
    font-size: 12px;
    pointer-events: none;
    border-right-color: #ddd;
    background-color: #ddd;

    .ant-btn {
      display: none;
    }
  }

  .ag-overlay-no-rows-center {
    margin-top: 30px;
  }
`;

export const SubSectionTitle = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin: 20px 0;
`;

export const StyledCheckCircleOutline = styled(CheckCircleOutlined)`
  color: ${(p) => p.theme.green};
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
`;

export const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${(p) => p.theme.primary_yellow};
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
`;

export const StyledLabel = styled.label`
  color: ${(p) => p.theme.light_gray};
  text-decoration: none;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  display: block;
  margin-bottom: 5px;
`;

export const TypeSelectorsWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & > div:first-child {
    margin-right: 30px;
  }
`;
