import React from 'react';

import { SpinnerWrapper, StyledSpin } from './styles';

type SpinnerProps = {
  size?: 'small' | 'default' | 'large';
};

const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  return (
    <SpinnerWrapper>
      <StyledSpin size={size} />
    </SpinnerWrapper>
  );
};

export default Spinner;
