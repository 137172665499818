import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab:hover {
    color: ${(p) => p.theme.black};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(p) => p.theme.black};
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    background: ${(p) => p.theme.primary_yellow};
  }

  .ant-tabs-content-holder {
    margin-top: 15px;
  }
`;
