import React, { useEffect } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useError } from 'services/utils/index';

import { StyledToastContainer } from './styles';

const ErrorNotification: React.FC = () => {
  const { errors, clearErrors } = useError();

  useEffect(() => {
    errors?.map((error: { message: string; type: 'warning' | 'error' }) => {
      error.type === 'warning' && toast.warn(error.message, { autoClose: false, theme: 'colored' });
      error.type === 'error' && toast.error(error.message, { autoClose: 10000, pauseOnHover: true, theme: 'colored' });
    });

    return () => {
      clearErrors();
    };
  }, [errors, clearErrors]);

  return (
    <StyledToastContainer
      position="bottom-right"
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  );
};

export default ErrorNotification;
