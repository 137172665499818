import { ValueGetterParams } from 'ag-grid-community';

export const sortEmpty = (a: any, b: any, rowNodeA: any, rowNodeB: any, isDescending: any) => {
  if (isDescending) {
    return a ? (b ? a.localeCompare(b) : 1) : -1;
  }

  return a ? (b ? a.localeCompare(b) : -1) : 1;
};

export const defaultNumberValueGetter = (params: ValueGetterParams) => {
  const value = params.data?.[params.column.getColId()];

  return typeof value === 'number' ? Math.round(value) : value;
};
