import React from 'react';

import { ButtonProps } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';

import { StyledButton } from './styles';

type Props = ButtonProps & {
  text?: string;
  textKey?: string;
};

const NakedButton: React.FC<Props> = ({ text, textKey, children, ...rest }) => {
  const { t } = useTranslation('translation');

  return <StyledButton {...rest}>{textKey ? t(textKey) : text || children}</StyledButton>;
};

export default NakedButton;
