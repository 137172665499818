import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

const InputFormTooltip: React.FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation('translation');

  return (
    <Tooltip
      title={
        <Trans
          i18nKey={text}
          components={{
            1: <br />,
          }}
        />
      }
    >
      <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
    </Tooltip>
  );
};

export default InputFormTooltip;
