import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  white: '#FFFFFF',
  black: '#000000D9',
  primary_yellow: '#FFCF00',
  pale_yellow: '#FDE269',
  dark_gray: '#3C3C3F',
  light_gray: '#949496',
  green: '#4FAB16',
  light_green: '#b4e497',
  light_soft_gray: '#ddd',
  soft_gray: '#DCDDE0',
  link_blue: '#0048B5',
  light_blue: '#40a9ff',
  red: '#D7300C',
};

export default defaultTheme;
