import styled from 'styled-components';

import { TenantStatus } from 'services/graphql/main';

export const getStatusColor = (status: TenantStatus) => {
  switch (status) {
    case TenantStatus.Active:
      return '#4FAB16';
    case TenantStatus.Pending:
      return '#FFCF00';
    case TenantStatus.Inactive:
      return '#D7300C';
  }
};

export const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  & h3 {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 0px;
  }
`;

export const TableWrapper = styled.div`
  border: 2px solid ${(p) => p.theme.soft_gray};
  padding: 16px;
  margin-bottom: 16px;
`;

export const TableTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const ClientName = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
  color: ${(p) => p.theme.link_blue};
  cursor: pointer;
`;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 3px;
`;
