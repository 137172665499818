import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import envconfig from 'config/envconfig';
import { getCacheVal, isTokenCloseToExpiration, logout } from 'services/utils';

const httpLink = createHttpLink({
  uri: envconfig.apolloClientAddress,
});

const authLink = setContext((_, { headers }) => {
  const token = getCacheVal('token');
  token ? isTokenCloseToExpiration(token) : logout();
  const tenantCode = getCacheVal('tenantCode');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Tenant-Code': tenantCode ? tenantCode : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
