import React, { useState } from 'react';

import { ApolloError } from '@apollo/client/errors/index.js';
import { useTranslation } from 'react-i18next';

type Error = { message: string; type: 'warning' | 'error' };

type ErrorContextType = {
  addCustomError: (err: any, type: 'error' | 'warning') => void;
  addError: (err: ApolloError, type: 'error' | 'warning') => void;
  clearErrors: () => void;
  errors: Error[] | null;
};

export const ErrorContext = React.createContext<ErrorContextType>({} as ErrorContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const ErrorContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const { t } = useTranslation('translation');

  const [errors, setErrors] = useState<Error[] | null>(null);

  const clearErrors = () => setErrors(null);

  const addError = (err: ApolloError, type: 'error' | 'warning') => {
    err?.graphQLErrors?.forEach((singleError) => {
      const error: Error = {
        type,
        message: t(`errors.${singleError?.extensions?.code || singleError?.message}`),
      };
      setErrors((errors) => (errors ? [...errors, error] : [error]));
    });
  };

  const addCustomError = (err: any, type: 'error' | 'warning') => {
    const error: Error = {
      type,
      message: t(`errors.${err?.code || err?.message}`),
    };

    setErrors((errors) => (errors ? [...errors, error] : [error]));
  };

  return (
    <ErrorContext.Provider value={{ addCustomError, addError, clearErrors, errors }}>{children}</ErrorContext.Provider>
  );
};
