import React, { useCallback, useEffect, useState } from 'react';

import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FullSizeSpinner, PrimaryButton, Spinner } from 'components/UI';
import {
  TenantDetailsDto,
  useGetTenantCsvInputFolderQuery,
  useUpdateTenantCsvInputFolderMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { StyledSection, StyledSwitch } from './styles';
import { FormsValidation } from './types';

const hoursOptions = new Array(24)
  .fill(null)
  .map((_item, idx) => ({ value: idx, label: `${idx < 10 ? 0 : ''}${idx}` }));

type CsvFileInputs = {
  importFileDelimiter: TenantDetailsDto['importFileDelimiter'];
  importFolder: TenantDetailsDto['importFolder'];
  importHour: TenantDetailsDto['importHour'];
};

const validateMessages = {
  required: '${label} is required!',
};

const CsvFileInput: React.FC<{
  onFormValidation: React.Dispatch<React.SetStateAction<FormsValidation>>;
  tenantId: number;
}> = ({ tenantId, onFormValidation }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();
  const [isFtpUserCreatedEnabled, setOnFtpUserCreatedSection] = useState(false);
  const { addError } = useError();
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const successMessage = () => toast.success(t('common.successfully_saved'), { theme: 'colored' });

  const { data, loading, refetch } = useGetTenantCsvInputFolderQuery({
    variables: { id: tenantId },
  });

  const [updateTenant, { error: mutationError }] = useUpdateTenantCsvInputFolderMutation({
    onError: (err) => addError(err, 'error'),
    onCompleted: () => {
      setIsSaving(false);
      setIsSubmitButtonDisabled(true);
      successMessage();
    },
  });

  const isFormValid = useCallback(() => {
    return (
      Object.values(form.getFieldsValue()).filter((el: unknown) => el === null || el === undefined || el === '')
        .length === 0
    );
  }, [form]);

  useEffect(() => {
    if (data?.tenant.importFolder) setOnFtpUserCreatedSection(true);
  }, [data]);

  useEffect(() => {
    if (data) {
      onFormValidation((prev) => ({
        ...prev,
        csvFileInput: isFtpUserCreatedEnabled && isFormValid(),
      }));
    }
  }, [data, onFormValidation, isFormValid, isFtpUserCreatedEnabled]);

  const handleSubmit = async (inputsValues: CsvFileInputs) => {
    setIsSaving(true);

    await updateTenant({
      variables: {
        tenantInput: {
          id: tenantId,
          ...inputsValues,
        },
      },
    });

    if (!mutationError) await refetch();
  };

  const handleFtpUserCreated = (value: boolean) => setOnFtpUserCreatedSection(value);

  return !loading && data?.tenant ? (
    <Form
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={data.tenant}
      form={form}
      validateMessages={validateMessages}
      onValuesChange={() => {
        setIsSubmitButtonDisabled(false);

        onFormValidation((forms) => ({
          ...forms,
          csvFileInput: isFormValid(),
        }));
      }}
      style={{ position: 'relative' }}
    >
      <StyledSection>
        {isSaving ? <FullSizeSpinner /> : null}

        <div>
          <Form.Item rules={[{ required: true }]} label={t('clientDetails.ftp_user_created')} valuePropName="checked">
            <StyledSwitch
              onChange={handleFtpUserCreated}
              checkedChildren={t('common.yes')}
              unCheckedChildren={t('common.no')}
              checked={isFtpUserCreatedEnabled}
            />
          </Form.Item>
        </div>

        {isFtpUserCreatedEnabled && (
          <>
            <Form.Item
              name="importFolder"
              label={t('clientDetails.input_file_folder_name')}
              rules={[{ required: true }]}
            >
              <Input placeholder="INPUT" />
            </Form.Item>

            {/* TODO defaultValue={7} */}
            <Form.Item name="importHour" label={t('clientDetails.import_time')} rules={[{ required: true }]}>
              <Select options={hoursOptions} style={{ width: '70px' }} />
            </Form.Item>

            <Form.Item
              name="importFileDelimiter"
              label={t('clientDetails.csv_file_delimter')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <PrimaryButton
              type="primary"
              textKey="common.save"
              htmlType="submit"
              style={{ marginTop: '30px' }}
              disabled={!isFormValid() || isSubmitButtonDisabled}
            />
          </>
        )}
      </StyledSection>
    </Form>
  ) : (
    <Spinner size="large" />
  );
};

export default CsvFileInput;
