import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { TenantsContext, MappingContext } from 'context';
import { isEqual, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Spinner, ValidationIcon } from 'components/UI';
import {
  TenantStatus,
  EntityTypeEnum,
  useGetTenantStoreCountQuery,
  useUpdateTenantMutation,
} from 'services/graphql/main';
import { setCacheVal, useError } from 'services/utils';

import CsvFileInput from './components/CsvFileInput';
import DataMapping from './components/DataMapping';
import MainInformation from './components/MainInformation';
import OptistockManagement from './components/OptistockManagement';
import OutputFileSettings from './components/OutputFileSettings';
import { FormsValidation } from './components/types';
import UsersManagement from './components/UsersManagement';
import { getStatusColor, StatusButton, StoreCountWrapper, StyledCollapse, TitleLine, TitleWrapper } from './styles';

const ClientDetails: React.FC = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { code } = useParams();
  const { addError } = useError();
  const { getTenantDetails, refetch } = useContext(TenantsContext);
  const { mappedTypes } = useContext(MappingContext);
  const [areFormsValid, setFormsValidation] = useState<FormsValidation>({
    optistockManagement: false,
    usersManagement: false,
    csvFileInput: false,
    outputFileSettings: false,
  });

  const tenantDetails = code ? getTenantDetails(code) : undefined;

  const { data: storeCountData } = useGetTenantStoreCountQuery({
    variables: { id: tenantDetails?.id || 0 },
  });

  const [updateTenant, { error: mutationError }] = useUpdateTenantMutation({
    onError: (err) => addError(err, 'error'),
  });

  const changeTenantStatus = async () => {
    if (tenantDetails?.status === TenantStatus.Active) {
      await updateTenant({
        variables: {
          tenantInput: { id: tenantDetails.id, status: TenantStatus.Inactive },
        },
      });
    } else if (tenantDetails?.status === TenantStatus.Pending || tenantDetails?.status === TenantStatus.Inactive) {
      await updateTenant({
        variables: {
          tenantInput: { id: tenantDetails.id, status: TenantStatus.Active },
        },
      });
    }
    if (!mutationError) {
      await refetch();
    }
  };

  useEffect(() => {
    code && setCacheVal('tenantCode', code);
  }, [code]);

  return tenantDetails ? (
    <>
      <TitleLine>
        <TitleWrapper>
          <ArrowLeftOutlined style={{ color: '#0048B5', fontSize: 20 }} onClick={() => navigate(-1)} />
          <h4>{tenantDetails?.name}</h4>
          <Tag color={tenantDetails?.status ? getStatusColor(tenantDetails?.status) : ''}>
            {t(`clients.${tenantDetails?.status}`).toUpperCase()}
          </Tag>
        </TitleWrapper>
        {tenantDetails?.status === TenantStatus.Active && storeCountData && (
          <StoreCountWrapper>
            <p>
              {t('clientDetails.active_stores_count')}
              <strong>{storeCountData.tenant.storeCount}</strong>
            </p>
          </StoreCountWrapper>
        )}
        <StatusButton
          action={tenantDetails?.status === TenantStatus.Active ? 'deactivate' : 'activate'}
          onClick={changeTenantStatus}
          disabled={
            (!areFormsValid.optistockManagement ||
              !areFormsValid.usersManagement ||
              !areFormsValid.csvFileInput ||
              !areFormsValid.outputFileSettings ||
              !isEqual(sortBy(mappedTypes), sortBy(Object.keys(EntityTypeEnum)))) &&
            tenantDetails?.status === TenantStatus.Pending
          }
        >
          {t(`clientDetails.${tenantDetails?.status === TenantStatus.Active ? 'deactivate' : 'activate'}`)}
        </StatusButton>
      </TitleLine>
      <MainInformation tenantDetails={tenantDetails} />

      <StyledCollapse ghost>
        <StyledCollapse.Panel
          forceRender
          header={
            <>
              {t('clientDetails.user_management')}
              <ValidationIcon isValid={areFormsValid.usersManagement} />
            </>
          }
          key="1"
        >
          <UsersManagement tenantCode={code} onFormValidation={setFormsValidation} />
        </StyledCollapse.Panel>
        <StyledCollapse.Panel
          header={
            <>
              {t('clientDetails.data_mapping')}
              <ValidationIcon isValid={isEqual(sortBy(mappedTypes), sortBy(Object.keys(EntityTypeEnum)))} />
            </>
          }
          key="2"
        >
          <DataMapping tenantId={tenantDetails.id} />
        </StyledCollapse.Panel>
        <StyledCollapse.Panel
          forceRender
          header={
            <>
              {t('clientDetails.csv_file_input_folder')}
              <ValidationIcon isValid={areFormsValid.csvFileInput} />
            </>
          }
          key="3"
        >
          <CsvFileInput tenantId={tenantDetails.id} onFormValidation={setFormsValidation} />
        </StyledCollapse.Panel>
        <StyledCollapse.Panel
          forceRender
          header={
            <>
              {t('clientDetails.optistock_management')} <ValidationIcon isValid={areFormsValid.optistockManagement} />
            </>
          }
          key="4"
        >
          <OptistockManagement tenantId={tenantDetails.id} onFormValidation={setFormsValidation} />
        </StyledCollapse.Panel>
        <StyledCollapse.Panel
          forceRender
          header={
            <>
              {t('clientDetails.output_file_settings')} <ValidationIcon isValid={areFormsValid.outputFileSettings} />
            </>
          }
          key="5"
        >
          <OutputFileSettings tenantId={tenantDetails.id} onFormValidation={setFormsValidation} />
        </StyledCollapse.Panel>
      </StyledCollapse>
    </>
  ) : (
    <Spinner size="large" />
  );
};

export default ClientDetails;
