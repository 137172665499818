import React from 'react';

import { CheckboxProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledCheckbox } from './styles';

type Props = CheckboxProps & {
  textKey?: string;
};

const Checkbox: React.FC<Props> = ({ textKey, children, ...rest }) => {
  const { t } = useTranslation('translation');

  return <StyledCheckbox {...rest}>{textKey ? t(textKey) : children}</StyledCheckbox>;
};

export default Checkbox;
