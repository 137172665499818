import React, { useState, useEffect } from 'react';

import { AppstoreOutlined, NotificationOutlined } from '@ant-design/icons';
import ExampleLogo from 'assets/images/exampleLogo.png';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import AllRoutes from 'screens/AllRoutes';

import { StyledSider, StyledMenu, LogoWrapper } from './styles';

const Sider: React.FC = () => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const pageMainUrl = '/' + location.pathname.split('/')[1];

  const [currentSelected, setCurrentSelected] = useState(pageMainUrl);

  useEffect(() => {
    if (location) {
      if (currentSelected !== pageMainUrl) {
        setCurrentSelected(pageMainUrl);
      }
    }
  }, [location, currentSelected, pageMainUrl]);

  const items = [
    {
      key: AllRoutes.Clients,
      icon: <AppstoreOutlined />,
      label: <Link to={AllRoutes.Clients}>{t('common.clients')}</Link>,
      title: '',
    },
    {
      key: AllRoutes.News,
      icon: <NotificationOutlined />,
      label: <Link to={AllRoutes.News}>{t('common.news')}</Link>,
      title: '',
    },
  ];

  return (
    <StyledSider>
      <LogoWrapper>
        <img src={ExampleLogo} alt="" />
      </LogoWrapper>
      <StyledMenu mode="inline" items={items} selectedKeys={[currentSelected]} />
    </StyledSider>
  );
};

export default Sider;
