import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, NakedButton } from 'components/UI';

import { TitleContainer, TextContainer, StyledWarningOutlined, IconContainer } from './styles';

interface PropsConfirmModal {
  customTitle?: string;
  customTitleKey?: string;
  icon?: JSX.Element | JSX.Element[];
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
}
const ConfirmModal: React.FC<PropsConfirmModal> = ({
  text,
  textKey,
  customTitle,
  customTitleKey,
  icon = <StyledWarningOutlined />,
  open,
  onCancel,
  onOk,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <Modal
        centered
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={[
          <NakedButton key="cancel" onClick={onCancel} style={{ border: 'none', marginRight: 15 }}>
            {t('common.cancel')}
          </NakedButton>,
          <PrimaryButton onClick={onOk} key="confirm" text={t('common.confirm')}></PrimaryButton>,
        ]}
        zIndex={2000}
      >
        <IconContainer>{icon}</IconContainer>
        <TitleContainer>{customTitleKey ? t(customTitleKey) : customTitle}</TitleContainer>
        <TextContainer>{textKey ? t(textKey) : text}</TextContainer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
