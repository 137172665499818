import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export enum ArticleTypeEnum {
  ColorCode = 'ColorCode',
  ColorName = 'ColorName',
  CostPrice = 'CostPrice',
  CustomFieldCode1 = 'CustomFieldCode1',
  CustomFieldCode2 = 'CustomFieldCode2',
  CustomFieldCode3 = 'CustomFieldCode3',
  CustomFieldCode4 = 'CustomFieldCode4',
  CustomFieldCode5 = 'CustomFieldCode5',
  CustomFieldName1 = 'CustomFieldName1',
  CustomFieldName2 = 'CustomFieldName2',
  CustomFieldName3 = 'CustomFieldName3',
  CustomFieldName4 = 'CustomFieldName4',
  CustomFieldName5 = 'CustomFieldName5',
  DepartmentCode = 'DepartmentCode',
  DepartmentName = 'DepartmentName',
  FamilyCode = 'FamilyCode',
  FamilyName = 'FamilyName',
  GenericReference = 'GenericReference',
  IsOnShowcase = 'IsOnShowcase',
  PhotoUrl = 'PhotoUrl',
  Price = 'Price',
  ProductCode = 'ProductCode',
  ProductName = 'ProductName',
  SeasonCode = 'SeasonCode',
  SeasonName = 'SeasonName',
  SizeName = 'SizeName',
  SizeSortNumber = 'SizeSortNumber',
  SizeSystemCode = 'SizeSystemCode',
  SizeSystemName = 'SizeSystemName',
  Sku = 'Sku',
  SubFamilyCode = 'SubFamilyCode',
  SubFamilyName = 'SubFamilyName',
  UniverseCode = 'UniverseCode',
  UniverseName = 'UniverseName'
}

export enum CapacityType {
  NotSet = 'NotSet',
  Pieces = 'Pieces',
  SquareMeters = 'SquareMeters'
}

export type CsvColumnInput = {
  index: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type CsvMapFieldInput = {
  columns?: InputMaybe<Array<InputMaybe<CsvColumnInput>>>;
  defaultValue?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CsvMapInput = {
  createRelatedItems: Scalars['Boolean'];
  dateFormat?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<InputMaybe<CsvMapFieldInput>>>;
  fileMask?: InputMaybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  model?: InputMaybe<EntityTypeEnum>;
};

export enum EntityTypeEnum {
  Article = 'Article',
  Sale = 'Sale',
  Size = 'Size',
  Store = 'Store',
  StoreStock = 'StoreStock',
  Supplier = 'Supplier',
  SupplierStock = 'SupplierStock',
  Warehouse = 'Warehouse',
  WarehouseStock = 'WarehouseStock'
}

export enum ErpType {
  As400 = 'AS400',
  Colombus = 'Colombus',
  Other = 'Other',
  Prios = 'Prios',
  Y2 = 'Y2'
}

export enum PermissionsEnum {
  ProductPerformanceDetailsEdit = 'ProductPerformance_Details_Edit',
  ProductPerformanceMainTableEdit = 'ProductPerformance_MainTable_Edit',
  SettingsEdit = 'Settings_Edit',
  SettingsGeneralSettingsEdit = 'Settings_GeneralSettings_Edit',
  SettingsGeneralSettingsView = 'Settings_GeneralSettings_View',
  SettingsUserManagementEdit = 'Settings_UserManagement_Edit',
  SettingsUserManagementView = 'Settings_UserManagement_View',
  SettingsView = 'Settings_View',
  StoreFleetMainTableDelete = 'StoreFleet_MainTable_Delete',
  StoreFleetMainTableEdit = 'StoreFleet_MainTable_Edit',
  StorePerformanceEdit = 'StorePerformance_Edit'
}

export enum PermutationFieldType {
  CustomField1 = 'CustomField1',
  CustomField2 = 'CustomField2',
  CustomField3 = 'CustomField3',
  CustomField4 = 'CustomField4',
  CustomField5 = 'CustomField5',
  Family = 'Family'
}

export enum SalesTypeEnum {
  PriceGrossWithVat = 'PriceGrossWithVat',
  PriceNetWithVat = 'PriceNetWithVat',
  Quantity = 'Quantity',
  Sku = 'Sku',
  StoreCode = 'StoreCode',
  StoreName = 'StoreName',
  Timestamp = 'Timestamp'
}

export enum SizeTypeEnum {
  SizeIsOnShowcase = 'SizeIsOnShowcase',
  SizeName = 'SizeName',
  SizeSortNumber = 'SizeSortNumber',
  SizeSystemCode = 'SizeSystemCode',
  SizeSystemName = 'SizeSystemName'
}

export enum StoreStockTypeEnum {
  QuantityAvailable = 'QuantityAvailable',
  QuantityPicking = 'QuantityPicking',
  QuantityTransit = 'QuantityTransit',
  Sku = 'Sku',
  StoreCode = 'StoreCode',
  StoreName = 'StoreName',
  Timestamp = 'Timestamp'
}

export enum StoreTypeEnum {
  Address1 = 'Address1',
  Address2 = 'Address2',
  AddressRegionCode = 'AddressRegionCode',
  AddressRegionName = 'AddressRegionName',
  Capacity = 'Capacity',
  City = 'City',
  Code = 'Code',
  CountryAlpha3 = 'CountryAlpha3',
  CountryCode = 'CountryCode',
  CountryName = 'CountryName',
  CustomFieldCode1 = 'CustomFieldCode1',
  CustomFieldCode2 = 'CustomFieldCode2',
  CustomFieldCode3 = 'CustomFieldCode3',
  CustomFieldCode4 = 'CustomFieldCode4',
  CustomFieldCode5 = 'CustomFieldCode5',
  CustomFieldName1 = 'CustomFieldName1',
  CustomFieldName2 = 'CustomFieldName2',
  CustomFieldName3 = 'CustomFieldName3',
  CustomFieldName4 = 'CustomFieldName4',
  CustomFieldName5 = 'CustomFieldName5',
  DepName = 'DepName',
  DepNum = 'DepNum',
  HandlesShowcase = 'HandlesShowcase',
  Name = 'Name',
  PickingPriority = 'PickingPriority',
  RegionName = 'RegionName',
  SupplierCode = 'SupplierCode',
  SupplierName = 'SupplierName',
  TypeCode = 'TypeCode',
  TypeName = 'TypeName',
  ZipCode = 'ZipCode'
}

export enum SupplierStockTypeEnum {
  QuantityForDelivery = 'QuantityForDelivery',
  Sku = 'Sku',
  SupplierCode = 'SupplierCode',
  SupplierName = 'SupplierName',
  Timestamp = 'Timestamp'
}

export enum SupplierTypeEnum {
  Code = 'Code',
  Name = 'Name',
  TypeName = 'TypeName'
}

export type Tenant = {
  __typename?: 'Tenant';
  assignSizeSystemFromFamily: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactWebsite?: Maybe<Scalars['String']>;
  contractEndDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  erpType?: Maybe<ErpType>;
  exportHour?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  importFileDelimiter?: Maybe<Scalars['String']>;
  importFileMappings?: Maybe<Scalars['String']>;
  importFolder?: Maybe<Scalars['String']>;
  importHour?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<TenantStatus>;
};

export type TenantCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactWebsite?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TenantDetailsDto = {
  __typename?: 'TenantDetailsDto';
  assignSizeSystemFromFamily: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactWebsite?: Maybe<Scalars['String']>;
  contractEndDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  erpType?: Maybe<ErpType>;
  excludeZeroValues: Scalars['Boolean'];
  exportFileDelimiter?: Maybe<Scalars['String']>;
  exportFileHeader?: Maybe<Scalars['String']>;
  exportFileLineTemplate?: Maybe<Scalars['String']>;
  exportFileName?: Maybe<Scalars['String']>;
  exportFolder?: Maybe<Scalars['String']>;
  exportHour?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  importFileDelimiter?: Maybe<Scalars['String']>;
  importFileMappings?: Maybe<Scalars['String']>;
  importFolder?: Maybe<Scalars['String']>;
  importHour?: Maybe<Scalars['Int']>;
  isErpMasterOfCreationAndModification: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  productCustomFieldName1?: Maybe<Scalars['String']>;
  productCustomFieldName2?: Maybe<Scalars['String']>;
  productCustomFieldName3?: Maybe<Scalars['String']>;
  productCustomFieldName4?: Maybe<Scalars['String']>;
  productCustomFieldName5?: Maybe<Scalars['String']>;
  productCustomFieldValues1?: Maybe<Scalars['String']>;
  productCustomFieldValues2?: Maybe<Scalars['String']>;
  productCustomFieldValues3?: Maybe<Scalars['String']>;
  productCustomFieldValues4?: Maybe<Scalars['String']>;
  productCustomFieldValues5?: Maybe<Scalars['String']>;
  sendChangedLogsOnly: Scalars['Boolean'];
  status?: Maybe<TenantStatus>;
  storeCount: Scalars['Int'];
  storeCustomFieldName1?: Maybe<Scalars['String']>;
  storeCustomFieldName2?: Maybe<Scalars['String']>;
  storeCustomFieldName3?: Maybe<Scalars['String']>;
  storeCustomFieldName4?: Maybe<Scalars['String']>;
  storeCustomFieldName5?: Maybe<Scalars['String']>;
  storeCustomFieldValues1?: Maybe<Scalars['String']>;
  storeCustomFieldValues2?: Maybe<Scalars['String']>;
  storeCustomFieldValues3?: Maybe<Scalars['String']>;
  storeCustomFieldValues4?: Maybe<Scalars['String']>;
  storeCustomFieldValues5?: Maybe<Scalars['String']>;
};

export type TenantSettings = {
  __typename?: 'TenantSettings';
  id: Scalars['Int'];
  isErpMasterOfCreationAndModification: Scalars['Boolean'];
  productCoefficient: Scalars['Decimal'];
  productCoverage: Scalars['Int'];
  productCustomFieldName1?: Maybe<Scalars['String']>;
  productCustomFieldName2?: Maybe<Scalars['String']>;
  productCustomFieldName3?: Maybe<Scalars['String']>;
  productCustomFieldName4?: Maybe<Scalars['String']>;
  productCustomFieldName5?: Maybe<Scalars['String']>;
  productCustomFieldValues1?: Maybe<Scalars['String']>;
  productCustomFieldValues2?: Maybe<Scalars['String']>;
  productCustomFieldValues3?: Maybe<Scalars['String']>;
  productCustomFieldValues4?: Maybe<Scalars['String']>;
  productCustomFieldValues5?: Maybe<Scalars['String']>;
  productMaxStrength: Scalars['Int'];
  storeAverageStrength: Scalars['Int'];
  storeCapacityType: CapacityType;
  storeCoefficient: Scalars['Decimal'];
  storeCustomFieldName1?: Maybe<Scalars['String']>;
  storeCustomFieldName2?: Maybe<Scalars['String']>;
  storeCustomFieldName3?: Maybe<Scalars['String']>;
  storeCustomFieldName4?: Maybe<Scalars['String']>;
  storeCustomFieldName5?: Maybe<Scalars['String']>;
  storeCustomFieldValues1?: Maybe<Scalars['String']>;
  storeCustomFieldValues2?: Maybe<Scalars['String']>;
  storeCustomFieldValues3?: Maybe<Scalars['String']>;
  storeCustomFieldValues4?: Maybe<Scalars['String']>;
  storeCustomFieldValues5?: Maybe<Scalars['String']>;
  storeMaxStrength: Scalars['Int'];
  storeNumberOfWeeksStrength: Scalars['Int'];
  storePerformancePermutationField1?: Maybe<PermutationFieldType>;
  storePerformancePermutationField2?: Maybe<PermutationFieldType>;
};

export type TenantSettingsUpdateInput = {
  id: Scalars['Int'];
  productCoefficient?: InputMaybe<Scalars['Decimal']>;
  productCoverage?: InputMaybe<Scalars['Int']>;
  productMaxStrength?: InputMaybe<Scalars['Int']>;
  storeAverageStrength?: InputMaybe<Scalars['Int']>;
  storeCapacityType?: InputMaybe<CapacityType>;
  storeCoefficient?: InputMaybe<Scalars['Decimal']>;
  storeMaxStrength?: InputMaybe<Scalars['Int']>;
  storeNumberOfWeeksStrength?: InputMaybe<Scalars['Int']>;
  storePerformancePermutationField1?: InputMaybe<PermutationFieldType>;
  storePerformancePermutationField2?: InputMaybe<PermutationFieldType>;
};

export enum TenantStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type TenantUpdateInput = {
  assignSizeSystemFromFamily?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactWebsite?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  erpType?: InputMaybe<ErpType>;
  excludeZeroValues?: InputMaybe<Scalars['Boolean']>;
  exportFileDelimiter?: InputMaybe<Scalars['String']>;
  exportFileHeader?: InputMaybe<Scalars['String']>;
  exportFileLineTemplate?: InputMaybe<Scalars['String']>;
  exportFileName?: InputMaybe<Scalars['String']>;
  exportFolder?: InputMaybe<Scalars['String']>;
  exportHour?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  importFileDelimiter?: InputMaybe<Scalars['String']>;
  importFileMappings?: InputMaybe<Array<InputMaybe<CsvMapInput>>>;
  importFolder?: InputMaybe<Scalars['String']>;
  importHour?: InputMaybe<Scalars['Int']>;
  isErpMasterOfCreationAndModification?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productCustomFieldName1?: InputMaybe<Scalars['String']>;
  productCustomFieldName2?: InputMaybe<Scalars['String']>;
  productCustomFieldName3?: InputMaybe<Scalars['String']>;
  productCustomFieldName4?: InputMaybe<Scalars['String']>;
  productCustomFieldName5?: InputMaybe<Scalars['String']>;
  productCustomFieldValues1?: InputMaybe<Scalars['String']>;
  productCustomFieldValues2?: InputMaybe<Scalars['String']>;
  productCustomFieldValues3?: InputMaybe<Scalars['String']>;
  productCustomFieldValues4?: InputMaybe<Scalars['String']>;
  productCustomFieldValues5?: InputMaybe<Scalars['String']>;
  sendChangedLogsOnly?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TenantStatus>;
  storeCustomFieldName1?: InputMaybe<Scalars['String']>;
  storeCustomFieldName2?: InputMaybe<Scalars['String']>;
  storeCustomFieldName3?: InputMaybe<Scalars['String']>;
  storeCustomFieldName4?: InputMaybe<Scalars['String']>;
  storeCustomFieldName5?: InputMaybe<Scalars['String']>;
  storeCustomFieldValues1?: InputMaybe<Scalars['String']>;
  storeCustomFieldValues2?: InputMaybe<Scalars['String']>;
  storeCustomFieldValues3?: InputMaybe<Scalars['String']>;
  storeCustomFieldValues4?: InputMaybe<Scalars['String']>;
  storeCustomFieldValues5?: InputMaybe<Scalars['String']>;
};

export type Universe = {
  __typename?: 'Universe';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type UniverseCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jobDescription?: Maybe<Scalars['String']>;
  userRoleId: UserRoleType;
  userSettings?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  jobDescription: Scalars['String'];
  userRoleType: UserRoleType;
  username: Scalars['String'];
};

export type UserInfoDto = {
  __typename?: 'UserInfoDto';
  permissions?: Maybe<Array<PermissionsEnum>>;
  userSettings?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum UserRoleType {
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

export type UserUpdateInput = {
  id: Scalars['Int'];
  jobDescription?: InputMaybe<Scalars['String']>;
  userRoleType?: InputMaybe<UserRoleType>;
  userSettings?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum WarehouseStockEnumType {
  QuantityAvailable = 'QuantityAvailable',
  Sku = 'Sku',
  Timestamp = 'Timestamp',
  WarehouseCode = 'WarehouseCode',
  WarehouseName = 'WarehouseName'
}

export enum WarehouseTypeEnum {
  Code = 'Code',
  IsRetail = 'IsRetail',
  Name = 'Name',
  SupplierCode = 'SupplierCode',
  SupplierName = 'SupplierName'
}

export type Mutations = {
  __typename?: 'mutations';
  bulkUpdateUser: Array<Maybe<User>>;
  createTenant: Tenant;
  createUniverse: Universe;
  createUser: User;
  deleteUser: Scalars['Boolean'];
  generateExport: Scalars['Boolean'];
  updateTenant: TenantDetailsDto;
  updateTenantSettings: Scalars['Boolean'];
  updateUser: User;
  updateUserSettings?: Maybe<Scalars['String']>;
};


export type MutationsBulkUpdateUserArgs = {
  usersInput?: InputMaybe<Array<InputMaybe<UserUpdateInput>>>;
};


export type MutationsCreateTenantArgs = {
  tenantInput?: InputMaybe<TenantCreateInput>;
};


export type MutationsCreateUniverseArgs = {
  universeCreateInput?: InputMaybe<UniverseCreateInput>;
};


export type MutationsCreateUserArgs = {
  userInput?: InputMaybe<UserCreateInput>;
};


export type MutationsDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationsUpdateTenantArgs = {
  tenantInput?: InputMaybe<TenantUpdateInput>;
};


export type MutationsUpdateTenantSettingsArgs = {
  tenantSettings?: InputMaybe<TenantSettingsUpdateInput>;
};


export type MutationsUpdateUserArgs = {
  userInput?: InputMaybe<UserUpdateInput>;
};


export type MutationsUpdateUserSettingsArgs = {
  userSettings?: InputMaybe<Scalars['String']>;
};

export type Queries = {
  __typename?: 'queries';
  requiredFields: Scalars['String'];
  tenant: TenantDetailsDto;
  tenantSettings: TenantSettings;
  tenants?: Maybe<Array<Tenant>>;
  universes?: Maybe<Array<Universe>>;
  user?: Maybe<Array<User>>;
  userRoles?: Maybe<Array<UserRole>>;
  userSettings: UserInfoDto;
  users?: Maybe<Array<User>>;
};


export type QueriesTenantArgs = {
  id: Scalars['Int'];
};


export type QueriesUserArgs = {
  userGuid: Scalars['String'];
};

export type CreateTenantMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantCreateInput>;
}>;


export type CreateTenantMutation = { __typename?: 'mutations', createTenant: { __typename?: 'Tenant', id: number, code?: string | null, erpType?: ErpType | null, name?: string | null, description?: string | null, status?: TenantStatus | null, contactEmail?: string | null, contactName?: string | null, contactWebsite?: string | null, contactPhone?: string | null, contractEndDate?: any | null } };

export type UpdateTenantMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateTenantMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', id: number, erpType?: ErpType | null, name?: string | null, description?: string | null, status?: TenantStatus | null, contactEmail?: string | null, contactName?: string | null, contactWebsite?: string | null, contactPhone?: string | null, contractEndDate?: any | null, isErpMasterOfCreationAndModification: boolean, importFileMappings?: string | null } };

export type UpdateTenantSettingsMutationVariables = Exact<{
  tenantSettings?: InputMaybe<TenantSettingsUpdateInput>;
}>;


export type UpdateTenantSettingsMutation = { __typename?: 'mutations', updateTenantSettings: boolean };

export type CreateUserMutationVariables = Exact<{
  userInput?: InputMaybe<UserCreateInput>;
}>;


export type CreateUserMutation = { __typename?: 'mutations', createUser: { __typename?: 'User', id: number } };

export type UpdateUserMutationVariables = Exact<{
  userInput?: InputMaybe<UserUpdateInput>;
}>;


export type UpdateUserMutation = { __typename?: 'mutations', updateUser: { __typename?: 'User', id: number } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'mutations', deleteUser: boolean };

export type UpdateTenantCsvInputFolderMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateTenantCsvInputFolderMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', id: number, importFolder?: string | null, importHour?: number | null, importFileDelimiter?: string | null } };

export type UpdateOutputFileSettingsMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateOutputFileSettingsMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', id: number, exportFileName?: string | null, erpType?: ErpType | null, exportFileHeader?: string | null, exportFileDelimiter?: string | null, exportFileLineTemplate?: string | null, sendChangedLogsOnly: boolean, excludeZeroValues: boolean, exportHour?: number | null, exportFolder?: string | null } };

export type UpdateProductCustomFieldsMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateProductCustomFieldsMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', productCustomFieldName1?: string | null, productCustomFieldName2?: string | null, productCustomFieldName3?: string | null, productCustomFieldName4?: string | null, productCustomFieldName5?: string | null, productCustomFieldValues1?: string | null, productCustomFieldValues2?: string | null, productCustomFieldValues3?: string | null, productCustomFieldValues4?: string | null, productCustomFieldValues5?: string | null } };

export type UpdateStoreCustomFieldsMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateStoreCustomFieldsMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', storeCustomFieldName1?: string | null, storeCustomFieldName2?: string | null, storeCustomFieldName3?: string | null, storeCustomFieldName4?: string | null, storeCustomFieldName5?: string | null, storeCustomFieldValues1?: string | null, storeCustomFieldValues2?: string | null, storeCustomFieldValues3?: string | null, storeCustomFieldValues4?: string | null, storeCustomFieldValues5?: string | null } };

export type UpdateOptistocManagmentTenantMutationVariables = Exact<{
  tenantInput?: InputMaybe<TenantUpdateInput>;
}>;


export type UpdateOptistocManagmentTenantMutation = { __typename?: 'mutations', updateTenant: { __typename?: 'TenantDetailsDto', id: number, isErpMasterOfCreationAndModification: boolean } };

export type CreateUniverseMutationVariables = Exact<{
  universeCreateInput?: InputMaybe<UniverseCreateInput>;
}>;


export type CreateUniverseMutation = { __typename?: 'mutations', createUniverse: { __typename?: 'Universe', id: number, code?: string | null, name?: string | null } };

export type BulkUpdateUserMutationVariables = Exact<{
  usersInput?: InputMaybe<Array<InputMaybe<UserUpdateInput>> | InputMaybe<UserUpdateInput>>;
}>;


export type BulkUpdateUserMutation = { __typename?: 'mutations', bulkUpdateUser: Array<{ __typename?: 'User', id: number, jobDescription?: string | null } | null> };

export type GetTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantsQuery = { __typename?: 'queries', tenants?: Array<{ __typename?: 'Tenant', id: number, erpType?: ErpType | null, name?: string | null, description?: string | null, status?: TenantStatus | null, contactEmail?: string | null, contactName?: string | null, contactWebsite?: string | null, contactPhone?: string | null, contractEndDate?: any | null, code?: string | null, importFileMappings?: string | null }> | null };

export type GetTenantCsvInputFolderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantCsvInputFolderQuery = { __typename?: 'queries', tenant: { __typename?: 'TenantDetailsDto', id: number, importFolder?: string | null, importHour?: number | null, importFileDelimiter?: string | null } };

export type GetOutputFileSettingsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOutputFileSettingsQuery = { __typename?: 'queries', tenant: { __typename?: 'TenantDetailsDto', id: number, exportFileName?: string | null, erpType?: ErpType | null, exportFileHeader?: string | null, exportFileDelimiter?: string | null, exportFileLineTemplate?: string | null, sendChangedLogsOnly: boolean, excludeZeroValues: boolean, exportHour?: number | null, exportFolder?: string | null } };

export type GetTenantStoreCountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantStoreCountQuery = { __typename?: 'queries', tenant: { __typename?: 'TenantDetailsDto', id: number, storeCount: number } };

export type GetTenantSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantSettingsQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', id: number, isErpMasterOfCreationAndModification: boolean, productCoefficient: any, productCoverage: number, storeCoefficient: any, productMaxStrength: number, storeAverageStrength: number, storeCapacityType: CapacityType, storeMaxStrength: number, storeNumberOfWeeksStrength: number, storePerformancePermutationField1?: PermutationFieldType | null, storePerformancePermutationField2?: PermutationFieldType | null } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'queries', users?: Array<{ __typename?: 'User', id: number, email?: string | null, username?: string | null, userRoleId: UserRoleType, jobDescription?: string | null }> | null };

export type UserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRolesQuery = { __typename?: 'queries', userRoles?: Array<{ __typename?: 'UserRole', id: number, name?: string | null }> | null };

export type GetProductCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductCustomFieldsQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', productCustomFieldName1?: string | null, productCustomFieldName2?: string | null, productCustomFieldName3?: string | null, productCustomFieldName4?: string | null, productCustomFieldName5?: string | null, productCustomFieldValues1?: string | null, productCustomFieldValues2?: string | null, productCustomFieldValues3?: string | null, productCustomFieldValues4?: string | null, productCustomFieldValues5?: string | null } };

export type GetStoreCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreCustomFieldsQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', storeCustomFieldName1?: string | null, storeCustomFieldName2?: string | null, storeCustomFieldName3?: string | null, storeCustomFieldName4?: string | null, storeCustomFieldName5?: string | null, storeCustomFieldValues1?: string | null, storeCustomFieldValues2?: string | null, storeCustomFieldValues3?: string | null, storeCustomFieldValues4?: string | null, storeCustomFieldValues5?: string | null } };

export type GetRequiredFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRequiredFieldsQuery = { __typename?: 'queries', requiredFields: string };


export const CreateTenantDocument = gql`
    mutation createTenant($tenantInput: TenantCreateInput) {
  createTenant(tenantInput: $tenantInput) {
    id
    code
    erpType
    name
    description
    status
    contactEmail
    contactName
    contactWebsite
    contactPhone
    contractEndDate
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation updateTenant($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    id
    erpType
    name
    description
    status
    contactEmail
    contactName
    contactWebsite
    contactPhone
    contractEndDate
    isErpMasterOfCreationAndModification
    importFileMappings
  }
}
    `;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const UpdateTenantSettingsDocument = gql`
    mutation UpdateTenantSettings($tenantSettings: TenantSettingsUpdateInput) {
  updateTenantSettings(tenantSettings: $tenantSettings)
}
    `;
export type UpdateTenantSettingsMutationFn = Apollo.MutationFunction<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>;

/**
 * __useUpdateTenantSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantSettingsMutation, { data, loading, error }] = useUpdateTenantSettingsMutation({
 *   variables: {
 *      tenantSettings: // value for 'tenantSettings'
 *   },
 * });
 */
export function useUpdateTenantSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>(UpdateTenantSettingsDocument, options);
      }
export type UpdateTenantSettingsMutationHookResult = ReturnType<typeof useUpdateTenantSettingsMutation>;
export type UpdateTenantSettingsMutationResult = Apollo.MutationResult<UpdateTenantSettingsMutation>;
export type UpdateTenantSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($userInput: UserCreateInput) {
  createUser(userInput: $userInput) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userInput: UserUpdateInput) {
  updateUser(userInput: $userInput) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: Int!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateTenantCsvInputFolderDocument = gql`
    mutation UpdateTenantCsvInputFolder($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    id
    importFolder
    importHour
    importFileDelimiter
  }
}
    `;
export type UpdateTenantCsvInputFolderMutationFn = Apollo.MutationFunction<UpdateTenantCsvInputFolderMutation, UpdateTenantCsvInputFolderMutationVariables>;

/**
 * __useUpdateTenantCsvInputFolderMutation__
 *
 * To run a mutation, you first call `useUpdateTenantCsvInputFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantCsvInputFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantCsvInputFolderMutation, { data, loading, error }] = useUpdateTenantCsvInputFolderMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateTenantCsvInputFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantCsvInputFolderMutation, UpdateTenantCsvInputFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantCsvInputFolderMutation, UpdateTenantCsvInputFolderMutationVariables>(UpdateTenantCsvInputFolderDocument, options);
      }
export type UpdateTenantCsvInputFolderMutationHookResult = ReturnType<typeof useUpdateTenantCsvInputFolderMutation>;
export type UpdateTenantCsvInputFolderMutationResult = Apollo.MutationResult<UpdateTenantCsvInputFolderMutation>;
export type UpdateTenantCsvInputFolderMutationOptions = Apollo.BaseMutationOptions<UpdateTenantCsvInputFolderMutation, UpdateTenantCsvInputFolderMutationVariables>;
export const UpdateOutputFileSettingsDocument = gql`
    mutation UpdateOutputFileSettings($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    id
    exportFileName
    erpType
    exportFileHeader
    exportFileDelimiter
    exportFileLineTemplate
    sendChangedLogsOnly
    excludeZeroValues
    exportHour
    exportFolder
  }
}
    `;
export type UpdateOutputFileSettingsMutationFn = Apollo.MutationFunction<UpdateOutputFileSettingsMutation, UpdateOutputFileSettingsMutationVariables>;

/**
 * __useUpdateOutputFileSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOutputFileSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutputFileSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutputFileSettingsMutation, { data, loading, error }] = useUpdateOutputFileSettingsMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateOutputFileSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutputFileSettingsMutation, UpdateOutputFileSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutputFileSettingsMutation, UpdateOutputFileSettingsMutationVariables>(UpdateOutputFileSettingsDocument, options);
      }
export type UpdateOutputFileSettingsMutationHookResult = ReturnType<typeof useUpdateOutputFileSettingsMutation>;
export type UpdateOutputFileSettingsMutationResult = Apollo.MutationResult<UpdateOutputFileSettingsMutation>;
export type UpdateOutputFileSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOutputFileSettingsMutation, UpdateOutputFileSettingsMutationVariables>;
export const UpdateProductCustomFieldsDocument = gql`
    mutation updateProductCustomFields($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    productCustomFieldName1
    productCustomFieldName2
    productCustomFieldName3
    productCustomFieldName4
    productCustomFieldName5
    productCustomFieldValues1
    productCustomFieldValues2
    productCustomFieldValues3
    productCustomFieldValues4
    productCustomFieldValues5
  }
}
    `;
export type UpdateProductCustomFieldsMutationFn = Apollo.MutationFunction<UpdateProductCustomFieldsMutation, UpdateProductCustomFieldsMutationVariables>;

/**
 * __useUpdateProductCustomFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateProductCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCustomFieldsMutation, { data, loading, error }] = useUpdateProductCustomFieldsMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateProductCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCustomFieldsMutation, UpdateProductCustomFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCustomFieldsMutation, UpdateProductCustomFieldsMutationVariables>(UpdateProductCustomFieldsDocument, options);
      }
export type UpdateProductCustomFieldsMutationHookResult = ReturnType<typeof useUpdateProductCustomFieldsMutation>;
export type UpdateProductCustomFieldsMutationResult = Apollo.MutationResult<UpdateProductCustomFieldsMutation>;
export type UpdateProductCustomFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateProductCustomFieldsMutation, UpdateProductCustomFieldsMutationVariables>;
export const UpdateStoreCustomFieldsDocument = gql`
    mutation updateStoreCustomFields($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    storeCustomFieldName1
    storeCustomFieldName2
    storeCustomFieldName3
    storeCustomFieldName4
    storeCustomFieldName5
    storeCustomFieldValues1
    storeCustomFieldValues2
    storeCustomFieldValues3
    storeCustomFieldValues4
    storeCustomFieldValues5
  }
}
    `;
export type UpdateStoreCustomFieldsMutationFn = Apollo.MutationFunction<UpdateStoreCustomFieldsMutation, UpdateStoreCustomFieldsMutationVariables>;

/**
 * __useUpdateStoreCustomFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateStoreCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreCustomFieldsMutation, { data, loading, error }] = useUpdateStoreCustomFieldsMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateStoreCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreCustomFieldsMutation, UpdateStoreCustomFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreCustomFieldsMutation, UpdateStoreCustomFieldsMutationVariables>(UpdateStoreCustomFieldsDocument, options);
      }
export type UpdateStoreCustomFieldsMutationHookResult = ReturnType<typeof useUpdateStoreCustomFieldsMutation>;
export type UpdateStoreCustomFieldsMutationResult = Apollo.MutationResult<UpdateStoreCustomFieldsMutation>;
export type UpdateStoreCustomFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateStoreCustomFieldsMutation, UpdateStoreCustomFieldsMutationVariables>;
export const UpdateOptistocManagmentTenantDocument = gql`
    mutation UpdateOptistocManagmentTenant($tenantInput: TenantUpdateInput) {
  updateTenant(tenantInput: $tenantInput) {
    id
    isErpMasterOfCreationAndModification
  }
}
    `;
export type UpdateOptistocManagmentTenantMutationFn = Apollo.MutationFunction<UpdateOptistocManagmentTenantMutation, UpdateOptistocManagmentTenantMutationVariables>;

/**
 * __useUpdateOptistocManagmentTenantMutation__
 *
 * To run a mutation, you first call `useUpdateOptistocManagmentTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptistocManagmentTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptistocManagmentTenantMutation, { data, loading, error }] = useUpdateOptistocManagmentTenantMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *   },
 * });
 */
export function useUpdateOptistocManagmentTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptistocManagmentTenantMutation, UpdateOptistocManagmentTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptistocManagmentTenantMutation, UpdateOptistocManagmentTenantMutationVariables>(UpdateOptistocManagmentTenantDocument, options);
      }
export type UpdateOptistocManagmentTenantMutationHookResult = ReturnType<typeof useUpdateOptistocManagmentTenantMutation>;
export type UpdateOptistocManagmentTenantMutationResult = Apollo.MutationResult<UpdateOptistocManagmentTenantMutation>;
export type UpdateOptistocManagmentTenantMutationOptions = Apollo.BaseMutationOptions<UpdateOptistocManagmentTenantMutation, UpdateOptistocManagmentTenantMutationVariables>;
export const CreateUniverseDocument = gql`
    mutation CreateUniverse($universeCreateInput: UniverseCreateInput) {
  createUniverse(universeCreateInput: $universeCreateInput) {
    id
    code
    name
  }
}
    `;
export type CreateUniverseMutationFn = Apollo.MutationFunction<CreateUniverseMutation, CreateUniverseMutationVariables>;

/**
 * __useCreateUniverseMutation__
 *
 * To run a mutation, you first call `useCreateUniverseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniverseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniverseMutation, { data, loading, error }] = useCreateUniverseMutation({
 *   variables: {
 *      universeCreateInput: // value for 'universeCreateInput'
 *   },
 * });
 */
export function useCreateUniverseMutation(baseOptions?: Apollo.MutationHookOptions<CreateUniverseMutation, CreateUniverseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUniverseMutation, CreateUniverseMutationVariables>(CreateUniverseDocument, options);
      }
export type CreateUniverseMutationHookResult = ReturnType<typeof useCreateUniverseMutation>;
export type CreateUniverseMutationResult = Apollo.MutationResult<CreateUniverseMutation>;
export type CreateUniverseMutationOptions = Apollo.BaseMutationOptions<CreateUniverseMutation, CreateUniverseMutationVariables>;
export const BulkUpdateUserDocument = gql`
    mutation BulkUpdateUser($usersInput: [UserUpdateInput]) {
  bulkUpdateUser(usersInput: $usersInput) {
    id
    jobDescription
  }
}
    `;
export type BulkUpdateUserMutationFn = Apollo.MutationFunction<BulkUpdateUserMutation, BulkUpdateUserMutationVariables>;

/**
 * __useBulkUpdateUserMutation__
 *
 * To run a mutation, you first call `useBulkUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateUserMutation, { data, loading, error }] = useBulkUpdateUserMutation({
 *   variables: {
 *      usersInput: // value for 'usersInput'
 *   },
 * });
 */
export function useBulkUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateUserMutation, BulkUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateUserMutation, BulkUpdateUserMutationVariables>(BulkUpdateUserDocument, options);
      }
export type BulkUpdateUserMutationHookResult = ReturnType<typeof useBulkUpdateUserMutation>;
export type BulkUpdateUserMutationResult = Apollo.MutationResult<BulkUpdateUserMutation>;
export type BulkUpdateUserMutationOptions = Apollo.BaseMutationOptions<BulkUpdateUserMutation, BulkUpdateUserMutationVariables>;
export const GetTenantsDocument = gql`
    query GetTenants {
  tenants {
    id
    erpType
    name
    description
    status
    contactEmail
    contactName
    contactWebsite
    contactPhone
    contractEndDate
    code
    importFileMappings
  }
}
    `;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetTenantCsvInputFolderDocument = gql`
    query GetTenantCsvInputFolder($id: Int!) {
  tenant(id: $id) {
    id
    importFolder
    importHour
    importFileDelimiter
  }
}
    `;

/**
 * __useGetTenantCsvInputFolderQuery__
 *
 * To run a query within a React component, call `useGetTenantCsvInputFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantCsvInputFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantCsvInputFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantCsvInputFolderQuery(baseOptions: Apollo.QueryHookOptions<GetTenantCsvInputFolderQuery, GetTenantCsvInputFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantCsvInputFolderQuery, GetTenantCsvInputFolderQueryVariables>(GetTenantCsvInputFolderDocument, options);
      }
export function useGetTenantCsvInputFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantCsvInputFolderQuery, GetTenantCsvInputFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantCsvInputFolderQuery, GetTenantCsvInputFolderQueryVariables>(GetTenantCsvInputFolderDocument, options);
        }
export type GetTenantCsvInputFolderQueryHookResult = ReturnType<typeof useGetTenantCsvInputFolderQuery>;
export type GetTenantCsvInputFolderLazyQueryHookResult = ReturnType<typeof useGetTenantCsvInputFolderLazyQuery>;
export type GetTenantCsvInputFolderQueryResult = Apollo.QueryResult<GetTenantCsvInputFolderQuery, GetTenantCsvInputFolderQueryVariables>;
export const GetOutputFileSettingsDocument = gql`
    query GetOutputFileSettings($id: Int!) {
  tenant(id: $id) {
    id
    exportFileName
    erpType
    exportFileHeader
    exportFileDelimiter
    exportFileLineTemplate
    sendChangedLogsOnly
    excludeZeroValues
    exportHour
    exportFolder
  }
}
    `;

/**
 * __useGetOutputFileSettingsQuery__
 *
 * To run a query within a React component, call `useGetOutputFileSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputFileSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputFileSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOutputFileSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetOutputFileSettingsQuery, GetOutputFileSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputFileSettingsQuery, GetOutputFileSettingsQueryVariables>(GetOutputFileSettingsDocument, options);
      }
export function useGetOutputFileSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputFileSettingsQuery, GetOutputFileSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputFileSettingsQuery, GetOutputFileSettingsQueryVariables>(GetOutputFileSettingsDocument, options);
        }
export type GetOutputFileSettingsQueryHookResult = ReturnType<typeof useGetOutputFileSettingsQuery>;
export type GetOutputFileSettingsLazyQueryHookResult = ReturnType<typeof useGetOutputFileSettingsLazyQuery>;
export type GetOutputFileSettingsQueryResult = Apollo.QueryResult<GetOutputFileSettingsQuery, GetOutputFileSettingsQueryVariables>;
export const GetTenantStoreCountDocument = gql`
    query GetTenantStoreCount($id: Int!) {
  tenant(id: $id) {
    id
    storeCount
  }
}
    `;

/**
 * __useGetTenantStoreCountQuery__
 *
 * To run a query within a React component, call `useGetTenantStoreCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantStoreCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantStoreCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantStoreCountQuery(baseOptions: Apollo.QueryHookOptions<GetTenantStoreCountQuery, GetTenantStoreCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantStoreCountQuery, GetTenantStoreCountQueryVariables>(GetTenantStoreCountDocument, options);
      }
export function useGetTenantStoreCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantStoreCountQuery, GetTenantStoreCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantStoreCountQuery, GetTenantStoreCountQueryVariables>(GetTenantStoreCountDocument, options);
        }
export type GetTenantStoreCountQueryHookResult = ReturnType<typeof useGetTenantStoreCountQuery>;
export type GetTenantStoreCountLazyQueryHookResult = ReturnType<typeof useGetTenantStoreCountLazyQuery>;
export type GetTenantStoreCountQueryResult = Apollo.QueryResult<GetTenantStoreCountQuery, GetTenantStoreCountQueryVariables>;
export const GetTenantSettingsDocument = gql`
    query GetTenantSettings {
  tenantSettings {
    id
    isErpMasterOfCreationAndModification
    productCoefficient
    productCoverage
    storeCoefficient
    productMaxStrength
    storeAverageStrength
    storeCapacityType
    storeMaxStrength
    storeNumberOfWeeksStrength
    storePerformancePermutationField1
    storePerformancePermutationField2
  }
}
    `;

/**
 * __useGetTenantSettingsQuery__
 *
 * To run a query within a React component, call `useGetTenantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
      }
export function useGetTenantSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
        }
export type GetTenantSettingsQueryHookResult = ReturnType<typeof useGetTenantSettingsQuery>;
export type GetTenantSettingsLazyQueryHookResult = ReturnType<typeof useGetTenantSettingsLazyQuery>;
export type GetTenantSettingsQueryResult = Apollo.QueryResult<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    email
    username
    userRoleId
    jobDescription
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UserRolesDocument = gql`
    query UserRoles {
  userRoles {
    id
    name
  }
}
    `;

/**
 * __useUserRolesQuery__
 *
 * To run a query within a React component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
      }
export function useUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
        }
export type UserRolesQueryHookResult = ReturnType<typeof useUserRolesQuery>;
export type UserRolesLazyQueryHookResult = ReturnType<typeof useUserRolesLazyQuery>;
export type UserRolesQueryResult = Apollo.QueryResult<UserRolesQuery, UserRolesQueryVariables>;
export const GetProductCustomFieldsDocument = gql`
    query GetProductCustomFields {
  tenantSettings {
    productCustomFieldName1
    productCustomFieldName2
    productCustomFieldName3
    productCustomFieldName4
    productCustomFieldName5
    productCustomFieldValues1
    productCustomFieldValues2
    productCustomFieldValues3
    productCustomFieldValues4
    productCustomFieldValues5
  }
}
    `;

/**
 * __useGetProductCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetProductCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>(GetProductCustomFieldsDocument, options);
      }
export function useGetProductCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>(GetProductCustomFieldsDocument, options);
        }
export type GetProductCustomFieldsQueryHookResult = ReturnType<typeof useGetProductCustomFieldsQuery>;
export type GetProductCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetProductCustomFieldsLazyQuery>;
export type GetProductCustomFieldsQueryResult = Apollo.QueryResult<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>;
export const GetStoreCustomFieldsDocument = gql`
    query GetStoreCustomFields {
  tenantSettings {
    storeCustomFieldName1
    storeCustomFieldName2
    storeCustomFieldName3
    storeCustomFieldName4
    storeCustomFieldName5
    storeCustomFieldValues1
    storeCustomFieldValues2
    storeCustomFieldValues3
    storeCustomFieldValues4
    storeCustomFieldValues5
  }
}
    `;

/**
 * __useGetStoreCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetStoreCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreCustomFieldsQuery, GetStoreCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreCustomFieldsQuery, GetStoreCustomFieldsQueryVariables>(GetStoreCustomFieldsDocument, options);
      }
export function useGetStoreCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreCustomFieldsQuery, GetStoreCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreCustomFieldsQuery, GetStoreCustomFieldsQueryVariables>(GetStoreCustomFieldsDocument, options);
        }
export type GetStoreCustomFieldsQueryHookResult = ReturnType<typeof useGetStoreCustomFieldsQuery>;
export type GetStoreCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetStoreCustomFieldsLazyQuery>;
export type GetStoreCustomFieldsQueryResult = Apollo.QueryResult<GetStoreCustomFieldsQuery, GetStoreCustomFieldsQueryVariables>;
export const GetRequiredFieldsDocument = gql`
    query GetRequiredFields {
  requiredFields
}
    `;

/**
 * __useGetRequiredFieldsQuery__
 *
 * To run a query within a React component, call `useGetRequiredFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequiredFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequiredFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequiredFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetRequiredFieldsQuery, GetRequiredFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequiredFieldsQuery, GetRequiredFieldsQueryVariables>(GetRequiredFieldsDocument, options);
      }
export function useGetRequiredFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequiredFieldsQuery, GetRequiredFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequiredFieldsQuery, GetRequiredFieldsQueryVariables>(GetRequiredFieldsDocument, options);
        }
export type GetRequiredFieldsQueryHookResult = ReturnType<typeof useGetRequiredFieldsQuery>;
export type GetRequiredFieldsLazyQueryHookResult = ReturnType<typeof useGetRequiredFieldsLazyQuery>;
export type GetRequiredFieldsQueryResult = Apollo.QueryResult<GetRequiredFieldsQuery, GetRequiredFieldsQueryVariables>;