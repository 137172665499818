import React, { useEffect, useState } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AllRoutes from 'screens/AllRoutes';
import { Tenant, TenantStatus } from 'services/graphql/main';

import { ActionButton, ClientName, getStatusColor, TableTitle, TableWrapper } from '../styles';

type DataType = {
  contactName?: string | null;
  contractEndDate?: string;
  key?: string | null;
  name?: string | null;
  status?: TenantStatus | null;
};

type Props = {
  status: TenantStatus;
  tenants?: (Tenant | null)[] | null;
  title: string;
};

const ClientsTable: React.FC<Props> = ({ status, tenants, title }) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const [data, setData] = useState<DataType[]>();

  useEffect(() => {
    if (tenants?.length) {
      setData(
        tenants.map((tenant) => ({
          contactName: tenant?.contactName,
          contractEndDate: tenant?.contractEndDate,
          key: tenant?.code,
          name: tenant?.name,
          status: tenant?.status,
        })),
      );
    }
  }, [tenants]);

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <ClientName onClick={() => record.key && navigate(AllRoutes.ClientDetails.replace(':code', record.key))}>
          {text}
        </ClientName>
      ),
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={getStatusColor(status)}>{t(`clients.${status}`).toUpperCase()}</Tag>,
    },
    {
      title: t('clients.main_contact'),
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: t('clients.contract_renewal_date'),
      key: 'contractEndDate',
      dataIndex: 'contractEndDate',
      render: (value) => value && <span>{moment(value).format('YYYY-MM-DD')}</span>,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <ActionButton>
          <FormOutlined
            style={{ color: '#0048B5', fontSize: 20 }}
            onClick={() => record.key && navigate(AllRoutes.ClientDetails.replace(':code', record.key))}
          />
        </ActionButton>
      ),
    },
  ];

  return data?.length ? (
    <TableWrapper>
      <TableTitle>{title}</TableTitle>
      <Table columns={columns} dataSource={data} pagination={false} />
    </TableWrapper>
  ) : null;
};

export default ClientsTable;
