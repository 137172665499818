import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledCheckCircleOutline = styled(CheckCircleOutlined)`
  color: ${(p) => p.theme.green};
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: 2px;
`;

export const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${(p) => p.theme.primary_yellow};
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: 2px;
`;
