import React, { useContext } from 'react';

import { EditOutlined, GlobalOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { TenantsContext } from 'context';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Tenant, useUpdateTenantMutation } from 'services/graphql/main';
import { useError } from 'services/utils';

import {
  DetailsItem,
  DetailsWrapper,
  InfoLineWrapper,
  MainInformationContent,
  SectionTitle,
  StyledInput,
  StyledInputWrapper,
} from './styles';

const MainInformation: React.FC<{ tenantDetails?: Tenant }> = ({ tenantDetails }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const { refetch } = useContext(TenantsContext);

  const [updateTenant, { error: mutationError }] = useUpdateTenantMutation({
    onError: (err) => addError(err, 'error'),
  });

  const updateDetails = async (value: string, field: string) => {
    if (tenantDetails && value !== tenantDetails[field as keyof typeof tenantDetails] && value !== '') {
      await updateTenant({
        variables: {
          tenantInput: { id: tenantDetails.id, [field as keyof Tenant]: value },
        },
      });

      if (!mutationError) {
        await refetch();
      }
    }
  };

  return (
    <MainInformationContent>
      <SectionTitle>{t('clientDetails.main_information')}</SectionTitle>
      <InfoLineWrapper>
        <StyledInputWrapper>
          <label htmlFor="name">{t('clientDetails.name')}</label>
          <StyledInput
            defaultValue={tenantDetails?.name ? tenantDetails.name : ''}
            name="name"
            id="name"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </StyledInputWrapper>
      </InfoLineWrapper>
      <InfoLineWrapper>
        <StyledInputWrapper>
          <label htmlFor="code">{t('clientDetails.code')}</label>
          <StyledInput
            defaultValue={tenantDetails?.code ? tenantDetails.code : ''}
            name="code"
            id="code"
            disabled
            style={{ border: 'none', background: 'transparent', color: 'black' }}
          />
        </StyledInputWrapper>
      </InfoLineWrapper>
      <InfoLineWrapper>
        <StyledInputWrapper>
          <label htmlFor="description">{t('clientDetails.description')}</label>

          <StyledInput
            defaultValue={tenantDetails?.description ? tenantDetails.description : ''}
            name="description"
            id="description"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </StyledInputWrapper>
      </InfoLineWrapper>
      <InfoLineWrapper>
        <StyledInputWrapper>
          <label htmlFor="contract_end_date">{t('clientDetails.contract_end_date')}</label>
          <DatePicker
            suffixIcon={''}
            style={{ width: '100%' }}
            defaultValue={
              tenantDetails?.contractEndDate ? moment(tenantDetails.contractEndDate, 'YYYY-MM-DD') : undefined
            }
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
            placeholder={''}
            id="contract_end_date"
            name="contractEndDate"
          />
          <EditOutlined />
        </StyledInputWrapper>
      </InfoLineWrapper>
      <DetailsWrapper>
        <DetailsItem>
          <UserOutlined />
          <StyledInput
            bordered={tenantDetails ? !tenantDetails.contactName : true}
            defaultValue={tenantDetails?.contactName ? tenantDetails.contactName : ''}
            name="contactName"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </DetailsItem>

        <DetailsItem>
          <PhoneOutlined />
          <StyledInput
            bordered={tenantDetails ? !tenantDetails.contactPhone : true}
            defaultValue={tenantDetails?.contactPhone ? tenantDetails.contactPhone : ''}
            name="contactPhone"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </DetailsItem>

        <DetailsItem>
          <MailOutlined />
          <StyledInput
            bordered={tenantDetails ? !tenantDetails.contactEmail : true}
            defaultValue={tenantDetails?.contactEmail ? tenantDetails.contactEmail : ''}
            name="contactEmail"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </DetailsItem>

        <DetailsItem>
          <GlobalOutlined />
          <StyledInput
            bordered={tenantDetails ? !tenantDetails.contactWebsite : true}
            defaultValue={tenantDetails?.contactWebsite ? tenantDetails.contactWebsite : ''}
            name="contactWebsite"
            onBlur={(event) => updateDetails(event.target.defaultValue, event.target.name)}
          />
          <EditOutlined />
        </DetailsItem>
      </DetailsWrapper>
    </MainInformationContent>
  );
};

export default MainInformation;
